import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import memoizeOne from 'memoize-one';
import classNames from 'classnames';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import DropdownOption from './DropdownOption';
import { bufferLast } from '../tools/rxjs-operators';
import {
  getKeyChar, getKeyCode, isKeyPrintable, KEYS,
} from '../tools/keyboard-helper';

export interface DropdownItemValue {
  type: 'value';
  value: string;
  label: string;
  labelSelected?: string;
}
interface DropdownItemDivider {
  type: 'divider';
  key: string;
}
export type DropdownItem = DropdownItemValue | DropdownItemDivider;

interface Props {
  options: Array<DropdownItem>;
  value: string;
  unselectValue: string;
  onChange?: (value: DropdownItemValue) => void;
  ariaLabel?: string;
}

interface State {
  isOpen: boolean;
  selectIndex: number;
}

class Dropdown extends React.PureComponent<Props, State> {
  static defaultProps = {
    unselectValue: '',
  };

  state: State = {
    isOpen: false,
    selectIndex: -1,
  };

  listContentRef = React.createRef<HTMLDivElement>();

  optionSelectedRef = React.createRef<HTMLButtonElement>();

  keyboardTypingSubject = new Subject<string>();

  keyboardTypingSubscription: Subscription;

  constructor(props: Props) {
    super(props);

    this.keyboardTypingSubscription = this.keyboardTypingSubject
      .pipe(
        bufferLast<string>(this.keyboardTypingSubject.pipe(debounceTime<string>(1000))),
        map<string[], string>(list => list.join('')),
      )
      .subscribe(this.onTypeText);
  }

  componentWillUnmount() {
    this.keyboardTypingSubscription.unsubscribe();
  }

  // prettier-ignore
  getItemsByIndex = memoizeOne(
    (options: Array<DropdownItem>) => {
      const indexed: Array<DropdownItemValue> = [];
      options.forEach((item) => {
        if (item.type === 'value') {
          indexed.push(item);
        }
      });
      return indexed;
    },
  );

  // prettier-ignore
  getItemsIndexByValue = memoizeOne(
    (options: Array<DropdownItem>) => {
      const indexedByValue: {[key: string]: number} = {};
      this.getItemsByIndex(options).forEach((item, index) => {
        indexedByValue[item.value] = index;
      });
      return indexedByValue;
    },
  );

  openDropdown = () => {
    const { value, options } = this.props;

    const selectIndex = this.getItemsByIndex(options).findIndex(v => v.value === value);
    this.setState({ selectIndex, isOpen: true });

    document.addEventListener('mousedown', this.onMouseDown);
  };

  closeDropdown = () => {
    this.setState({ isOpen: false });
    document.removeEventListener('mousedown', this.onMouseDown);
  };

  scrollToItem = () => {
    const focus = this.optionSelectedRef.current;
    const content = this.listContentRef.current;
    if (focus && content) {
      const focusRect = focus.getBoundingClientRect();
      const contentRect = content.getBoundingClientRect();

      const top = focusRect.top - contentRect.top + content.scrollTop;
      const bottom = top + focusRect.height;

      const visibleTop = content.scrollTop;
      const visibleBottom = visibleTop + contentRect.height;

      if (top < visibleTop) {
        content.scrollTo({
          top,
        });
      } else if (bottom > visibleBottom) {
        content.scrollTo({
          top: bottom - contentRect.height,
        });
      }
    }
  };

  onMouseDown = (event: MouseEvent) => {
    if (!this.listContentRef.current) {
      return;
    }

    if (event.target instanceof Element) {
      if (!this.listContentRef.current.contains(event.target)) {
        this.closeDropdown();
      }
    }
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (this.processKeyDown(event.nativeEvent)) {
      event.preventDefault();
    }
  };

  processKeyDown = (event: KeyboardEvent) => {
    const { options } = this.props;
    const { selectIndex, isOpen } = this.state;

    const code = getKeyCode(event);
    const itemsByIndex = this.getItemsByIndex(options);

    if (!isOpen) {
      switch (code) {
        case KEYS.ARROW_UP:
        case KEYS.ARROW_DOWN:
        case KEYS.ENTER:
        case KEYS.SPACE:
          this.openDropdown();
          return true;
      }

      return false;
    }

    switch (code) {
      case KEYS.ESCAPE:
        this.closeDropdown();
        return true;
      case KEYS.ARROW_UP:
        if (selectIndex > 0) {
          this.setState({ selectIndex: selectIndex - 1 }, this.scrollToItem);
        }
        return true;
      case KEYS.ARROW_DOWN:
        if (selectIndex < itemsByIndex.length - 1) {
          this.setState({ selectIndex: selectIndex + 1 }, this.scrollToItem);
        }
        return true;
      case KEYS.HOME:
        if (itemsByIndex.length > 0) {
          this.setState({ selectIndex: 0 }, this.scrollToItem);
        }
        return true;
      case KEYS.END:
        this.setState({ selectIndex: itemsByIndex.length - 1 }, this.scrollToItem);
        return true;
      case KEYS.PAGE_UP:
        this.setState({ selectIndex: Math.max(0, selectIndex - 10) }, this.scrollToItem);
        return true;
      case KEYS.PAGE_DOWN:
        this.setState({ selectIndex: Math.min(itemsByIndex.length - 1, selectIndex + 10) }, this.scrollToItem);
        return true;
      case KEYS.ENTER:
      case KEYS.SPACE:
        if (itemsByIndex[selectIndex]) {
          this.onSelect(itemsByIndex[selectIndex]);
        }
        return true;
      case KEYS.TAB:
        return true;
    }

    if (isKeyPrintable(event)) {
      this.keyboardTypingSubject.next(getKeyChar(event).toLowerCase());
      return true;
    }

    return false;
  };

  onTypeText = (text: string) => {
    if (text.length < 1) {
      return;
    }
    const { options } = this.props;
    const itemsByIndex = this.getItemsByIndex(options);

    const selectIndex = itemsByIndex.findIndex(({ label }) => label.toLowerCase().indexOf(text) === 0);
    if (selectIndex >= 0) {
      this.setState({ selectIndex }, this.scrollToItem);
    }
  };

  onSelect = (data: DropdownItemValue) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(data);
    }
    this.closeDropdown();
  };

  renderItem = (data: DropdownItem) => {
    if (data.type === 'divider') {
      return <hr className="dropdown-divider" key={data.key} />;
    }

    const { options } = this.props;
    const { selectIndex } = this.state;
    const isActive = this.getItemsByIndex(options)[selectIndex] === data;

    return (
      <DropdownOption<DropdownItemValue>
        key={data.value}
        data={data}
        isActive={isActive}
        onSelect={this.onSelect}
        elementRef={isActive ? this.optionSelectedRef : undefined}
      />
    );
  };

  getButtonText = () => {
    const { value, options, unselectValue } = this.props;

    const index = this.getItemsIndexByValue(options)[value];
    if (index !== undefined) {
      const item = this.getItemsByIndex(options)[index];
      if (item) {
        return item.labelSelected || item.label;
      }
    }
    return unselectValue;
  };

  render() {
    const { options, ariaLabel } = this.props;
    const { isOpen } = this.state;

    return (
      <div className={classNames('dropdown', { 'is-active': isOpen })}>
        <div className="dropdown-trigger">
          <button
            type="button"
            className="button"
            onClick={this.openDropdown}
            aria-haspopup="listbox"
            aria-label={ariaLabel}
            onKeyDown={this.onKeyDown}
          >
            <span>{this.getButtonText()}</span>
            <span className="icon is-small">
              <FontAwesomeIcon icon={faAngleDown} />
            </span>
          </button>
        </div>
        <div className="dropdown-menu">
          <div
            className="dropdown-content is-fixed"
            tabIndex={-1}
            role="listbox"
            aria-label={ariaLabel}
            ref={this.listContentRef}
          >
            {options.map(this.renderItem)}
          </div>
        </div>
      </div>
    );
  }
}

export default Dropdown;
