import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import RootState from '../../../redux/RootState';
import SignInComponent from './SignInComponent';
import SignOutComponent from './SignOutComponent';
import AuthState from '../../../redux/auth/auth-state';

interface Props {
  auth: AuthState;
}

class RootComponent extends React.PureComponent<Props> {
  render() {
    const { auth } = this.props;
    return (
      <div>
        {auth !== null ? <SignInComponent /> : <SignOutComponent />}
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(RootComponent);
