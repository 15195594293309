import React from 'react';
import { ApiMoneyOperation } from '../../../api/money-operation-api';
import logo from '../../../resources/logo.png';
import Avatar from '../../shared/components/Avatar';
import { formatDateLocale } from '../../../tools/functions/date';
import { money } from '../../../tools/functions/money';

interface Props {
  moneyOperation: ApiMoneyOperation;
}

class MoneyOperationRow extends React.PureComponent<Props> {
  render() {
    const { moneyOperation } = this.props;
    const {
      otherMember, executedAt, isNegative, amount, currency, label, fees,
    } = moneyOperation;

    let image = null;
    if (otherMember) {
      image = (
        <Avatar image={otherMember.avatar} initials={otherMember.initial} alt={otherMember.displayName} size={64} />
      );
    } else {
      image = <img className="is-rounded" alt="Koosmik" src={logo} />;
    }

    const title = otherMember ? otherMember.displayName : 'Koosmik';
    const date = formatDateLocale(executedAt, 'none', 'short');
    const description = label;

    let amountEl = null;
    if (isNegative) {
      amountEl = (
        <div className="is-vcentered">
          <div className="column is-8">
            <span className="tag is-danger is-size-5">
              <span className="has-text">-</span>
              {money(amount, currency)}
            </span>
          </div>
        </div>
      );
    } else {
      amountEl = (
        <div className="is-vcentered">
          <div className="column is-8">
            <span className="tag is-success is-size-5">
              {'+ '}
              {money(amount, currency)}
            </span>
          </div>
        </div>
      );
    }

    let feesEl = null;
    if (fees > 0) {
      feesEl = (
        <p>
          Fees:
          {money(fees, currency)}
        </p>
      );
    }

    return (
      <div className="money-operation-row">
        <div className="columns is-mobile">
          <div className="column is-narrow">
            <figure className="image is-64x64">{image}</figure>
          </div>
          <div className="column">
            <h3>{title}</h3>
            <em className="time-row">{date}</em>
            <p>{description}</p>
          </div>
          <div className="column is-narrow">
            {amountEl}
            {feesEl}
          </div>
        </div>
      </div>
    );
  }
}

export default MoneyOperationRow;
