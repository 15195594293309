import { SessionState } from './sessions-state';

// Types
export const SESSIONS_ACTION_ADD = 'SESSIONS_ACTION_ADD';
export const SESSIONS_ACTION_REVOKE = 'SESSIONS_ACTION_REVOKE';

// Action creators definitions

export interface SessionsActionAdd {
  type: typeof SESSIONS_ACTION_ADD;
  payload: SessionState;
}

export interface SessionsActionRevoke {
  type: typeof SESSIONS_ACTION_REVOKE;
  payload: {
    sessionId: string;
  };
}

export type SessionsActions = SessionsActionAdd | SessionsActionRevoke;

// Action creators

export const SessionsActionCreators = {
  add: (payload: SessionState): SessionsActionAdd => ({
    type: SESSIONS_ACTION_ADD,
    payload,
  }),
  revoke: (sessionId: string): SessionsActionRevoke => ({
    type: SESSIONS_ACTION_REVOKE,
    payload: { sessionId },
  }),
};
