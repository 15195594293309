import { ActionReject, ActionResolve } from '../../../redux/promises';
import {
  ApiSalaryPaymentBulkCreatePayload,
  ApiSalaryPaymentBulkCreateResponseData,
  ApiSalaryPaymentBulkGetPayload,
  ApiSalaryPaymentBulkGetResponseData,
  ApiSalaryPaymentBulkListResponseData,
} from '../../../api/salary-payment-bulk-api';

// Types

export const SALARY_PAYMENT_BULK_ACTION_LIST = 'SALARY_PAYMENT_BULK_ACTION_LIST';
export const SALARY_PAYMENT_BULK_ACTION_GET = 'SALARY_PAYMENT_BULK_ACTION_GET';
export const SALARY_PAYMENT_BULK_ACTION_CREATE = 'SALARY_PAYMENT_BULK_ACTION_CREATE';

// Actions creators definitions

// -- bulkList

export interface SalaryPaymentBulkActionListPayload {
  maxId?: string | null;
}

export interface SalaryPaymentBulkActionListResolvePayload {
  data: ApiSalaryPaymentBulkListResponseData;
}

export interface SalaryPaymentBulkActionList {
  type: typeof SALARY_PAYMENT_BULK_ACTION_LIST;
  payload: SalaryPaymentBulkActionListPayload;
  resolve: ActionResolve<SalaryPaymentBulkActionListResolvePayload>;
  reject: ActionReject;
}

// -- get

export interface SalaryPaymentBulkActionGetPayload extends ApiSalaryPaymentBulkGetPayload {}

export interface SalaryPaymentBulkActionGetResolvePayload {
  data: ApiSalaryPaymentBulkGetResponseData;
}

export interface SalaryPaymentBulkActionGet {
  type: typeof SALARY_PAYMENT_BULK_ACTION_GET;
  payload: SalaryPaymentBulkActionGetPayload;
  resolve: ActionResolve<SalaryPaymentBulkActionGetResolvePayload>;
  reject: ActionReject;
}

// -- create

export interface SalaryPaymentBulkActionCreatePayload extends ApiSalaryPaymentBulkCreatePayload {}

export interface SalaryPaymentBulkActionCreateResolvePayload {
  data: ApiSalaryPaymentBulkCreateResponseData;
}

export interface SalaryPaymentBulkActionCreate {
  type: typeof SALARY_PAYMENT_BULK_ACTION_CREATE;
  payload: SalaryPaymentBulkActionCreatePayload;
  resolve: ActionResolve<SalaryPaymentBulkActionCreateResolvePayload>;
  reject: ActionReject;
}

// -- enum

export type SalaryPaymentBulkActions =
  | SalaryPaymentBulkActionList
  | SalaryPaymentBulkActionGet
  | SalaryPaymentBulkActionCreate;

// Action creators

export const SalaryPaymentBulkActionCreators = {
  list: (
    payload: SalaryPaymentBulkActionListPayload,
    resolve: ActionResolve<SalaryPaymentBulkActionListResolvePayload>,
    reject: ActionReject,
  ): SalaryPaymentBulkActionList => ({
    type: SALARY_PAYMENT_BULK_ACTION_LIST,
    payload,
    resolve,
    reject,
  }),
  get: (
    payload: SalaryPaymentBulkActionGetPayload,
    resolve: ActionResolve<SalaryPaymentBulkActionGetResolvePayload>,
    reject: ActionReject,
  ): SalaryPaymentBulkActionGet => ({
    type: SALARY_PAYMENT_BULK_ACTION_GET,
    payload,
    resolve,
    reject,
  }),
  create: (
    payload: SalaryPaymentBulkActionCreatePayload,
    resolve: ActionResolve<SalaryPaymentBulkActionCreateResolvePayload>,
    reject: ActionReject,
  ): SalaryPaymentBulkActionCreate => ({
    type: SALARY_PAYMENT_BULK_ACTION_CREATE,
    payload,
    resolve,
    reject,
  }),
};
