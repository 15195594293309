import React, { RefObject } from 'react';
import classNames from 'classnames';

interface Data {
  value: string;
  label: string;
}

interface Props<T extends Data> {
  data: T;
  isActive: boolean;
  onSelect: (data: T) => void;
  elementRef: RefObject<HTMLButtonElement> | undefined;
}

class DropdownOption<T extends Data = Data> extends React.PureComponent<Props<T>> {
  onClick = () => {
    const { data, onSelect } = this.props;
    onSelect(data);
  };

  render() {
    const { data, isActive, elementRef } = this.props;

    return (
      <button
        type="button"
        className={classNames('dropdown-item', { 'is-active': isActive })}
        aria-selected={isActive}
        role="option"
        onClick={this.onClick}
        ref={elementRef}
      >
        {data.label}
      </button>
    );
  }
}

export default DropdownOption;
