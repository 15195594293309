import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import RootState from '../../../../redux/RootState';
import UserState from '../../../../redux/user/user-state';
import { RoutesUrls } from '../../../../config/Routes';
import logo from '../../../../resources/logo.png';
import { t } from '../../../../tools/functions/translation';
import { money } from '../../../../tools/functions/money';
import NavBarUserMenu from './NavBarUserMenu';
import NavBarGuard from './NavBarGuard';

interface Props {
  user: UserState;
}

interface State {
  isOpen: boolean;
}

class NavBarComponent extends React.PureComponent<Props, State> {
  state = {
    isOpen: false,
  };

  toggleSideMenu = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  renderWallets = () => {
    const { user } = this.props;
    if (!user) {
      return null;
    }

    return Object.entries(user.wallets).map(([currency, { balance }]) => (
      <div className="navbar-item" key={currency} aria-label={`Wallet ${currency}`}>
        {money(balance, currency)}
      </div>
    ));
  };

  render() {
    const { user } = this.props;
    const { isOpen } = this.state;
    return (
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link className="navbar-item" to={RoutesUrls.dashboard}>
            <img src={logo} width="28" height="28" alt="Koosmik" />
          </Link>
          <button
            type="button"
            onClick={this.toggleSideMenu}
            className={classNames('navbar-burger burger', { 'is-active': isOpen })}
            aria-label="menu"
            aria-expanded={isOpen}
            aria-controls="main-menu"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </button>
        </div>

        <div id="main-menu" className={classNames('navbar-menu', { 'is-active': isOpen })}>
          <div className="navbar-start">
            <NavBarGuard user={user} feature="salary-payment" type="enabled">
              <NavLink to={RoutesUrls.salaryPaymentEmployees} className="navbar-item" activeClassName="is-active">
                {t('Liste des salariés')}
              </NavLink>
              <NavLink to={RoutesUrls.salaryPaymentBulks} className="navbar-item" activeClassName="is-active">
                {t('Programmer un paiement')}
              </NavLink>
            </NavBarGuard>
          </div>

          <div className="navbar-end">
            {this.renderWallets()}
            <NavBarUserMenu user={user} />
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

export default connect(mapStateToProps)(NavBarComponent);
