import { call, takeEvery } from 'redux-saga/effects';
import { apiMoneyOperationList, ApiMoneyOperationListResponse } from '../../../api/money-operation-api';
import { MONEY_OPERATION_ACTION_LIST, MoneyOperationActionList } from './money-operation-actions';
import { apiCallWrapper } from '../../../tools/functions/api-call-handler';

function* moneyOperationList({ payload, resolve, reject }: MoneyOperationActionList) {
  const { maxId } = payload;
  try {
    const response: ApiMoneyOperationListResponse = yield call(apiCallWrapper, apiMoneyOperationList, {
      max_id: maxId,
      count: 20,
    });

    const { success, data, errorCode } = response;
    if (success) {
      resolve({ data });
      return;
    }

    reject({ errorCode });
  } catch (error) {
    reject({ error });
  }
}

export default function* moneyOperationSaga() {
  yield takeEvery(MONEY_OPERATION_ACTION_LIST, moneyOperationList);
}
