import get from 'lodash.get';
import { ApiResponseDataInputErrors, InputErrorsLeaf } from '../../../api/shared-api-lib';

export const extractInputErrors = (
  path: string,
  inputErrors: ApiResponseDataInputErrors | null,
): InputErrorsLeaf | null => {
  if (!inputErrors) {
    return null;
  }

  const errors = get(inputErrors, path);
  if (errors instanceof Object) {
    if (Object.values(errors).every(v => typeof v === 'string')) {
      return errors as InputErrorsLeaf;
    }
  }

  return null;
};
