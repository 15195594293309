import React from 'react';
import UserState from '../../../../redux/user/user-state';

interface Props {
  user: UserState;
  feature: string;
  type: 'enabled' | 'disabled';
  children: React.ReactNode;
}

class NavBarGuard extends React.PureComponent<Props> {
  render() {
    const {
      user, type, feature, children,
    } = this.props;

    if (!user) {
      return null;
    }

    if (type === 'enabled') {
      if (!user.enabledFeatures.includes(feature)) {
        return null;
      }
    } else if (user.restrictedFeatures.includes(feature)) {
      return null;
    }

    return children;
  }
}

export default NavBarGuard;
