import SessionsState, { SessionState } from './sessions-state';
import {
  SESSIONS_ACTION_ADD,
  SESSIONS_ACTION_REVOKE,
  SessionsActionAdd,
  SessionsActionRevoke,
  SessionsActions,
} from './sessions-actions';

const reduceAdd = (state: SessionsState, { payload }: SessionsActionAdd) => {
  if (payload.type === 'email') {
    const { email } = payload;
    const predicate = (el: SessionState) => !(el.type === 'email' && el.email === email);
    const retVal: SessionsState = [payload];
    return retVal.concat(state.filter(predicate));
  }

  if (payload.type === 'phone') {
    const { phone } = payload;
    // prettier-ignore
    const predicate = (el: SessionState) => !(
      el.type === 'phone' && el.phone.country === phone.country && el.phone.number === phone.number
    );
    const retVal: SessionsState = [payload];
    return retVal.concat(state.filter(predicate));
  }

  return state;
};

// prettier-ignore
const reduceRevoke = (state: SessionsState, { payload }: SessionsActionRevoke) => (
  state.filter(el => el.sessionId !== payload.sessionId)
);

const initialState: SessionsState = [];

export default function sessionsReducer(state: SessionsState = initialState, action: SessionsActions) {
  switch (action.type) {
    case SESSIONS_ACTION_ADD:
      return reduceAdd(state, action);
    case SESSIONS_ACTION_REVOKE:
      return reduceRevoke(state, action);
    default:
      return state;
  }
}
