import { ApiRequest } from 'koosmik-common';
import { UAParser } from 'ua-parser-js';
import Env from '../tools/Env';

export default function initApiRequest(deviceId: string) {
  const parser = new UAParser();

  const os = parser.getOS();
  const browser = parser.getBrowser();
  const client = `${os.name} ${browser.name}`.replace(/\s/g, '_');

  ApiRequest.setBaseURL(Env.apiUrl());
  ApiRequest.setClientInfo('web-pro', Env.version(), client, browser.version); // use user agent to fill
  ApiRequest.setDeviceId(deviceId);
}
