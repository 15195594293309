import DeviceState from './device-state';

// Types

export const DEVICE_ACTION_SET = 'DEVICE_ACTION_SET';

// Actions creator definitions

export interface DeviceActionSet {
  type: typeof DEVICE_ACTION_SET;
  payload: DeviceState;
}

export type DeviceActions = DeviceActionSet;

// Action creators

export const DeviceActionCreators = {
  set: (payload: DeviceState): DeviceActionSet => ({
    type: DEVICE_ACTION_SET,
    payload,
  }),
};
