import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight, faPaperPlane, faPlus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import RootState from '../../../redux/RootState';
import UserState from '../../../redux/user/user-state';
import {
  SalaryPaymentEmployeeActionCreators,
  SalaryPaymentEmployeeActionListPayload,
  SalaryPaymentEmployeeActionListResolvePayload,
} from '../redux/salary-payment-employee-actions';
import { Employee } from '../../../api/salary-payment-employee-api';
import EmployeeEditorModal from '../components/EmployeeEditorModal';
import { t } from '../../../tools/functions/translation';
import { actionRejectHandler } from '../../../tools/functions/action-promise-handler';
import BulkCoreEditor, { BulkEditorCoreValues } from '../components/BulkCoreEditor';
import {
  SalaryPaymentBulkActionCreatePayload,
  SalaryPaymentBulkActionCreateResolvePayload,
  SalaryPaymentBulkActionCreators,
} from '../redux/salary-payment-bulk-actions';
import BulkEmployeeList from '../components/BulkEmployeeList';
import { RoutesUrls } from '../../../config/Routes';
import EmployeeSelectorModal from '../components/EmployeeSelectorModal';
import { BulkEmployee } from '../components/BulkEmployeeLine';
import {
  CashActionFeesGridLoadResolvePayload,
  CashFeesGridActionCreators,
} from '../../shared/redux/cash-fees-grid/cash-fees-grid-actions';
import CashFeesGridState from '../../shared/redux/cash-fees-grid/cash-fees-grid-state';
import { ApiResponseDataInputErrors } from '../../../api/shared-api-lib';
import { handleFormSubmitEvent } from '../../../tools/functions/html-events-handler';

type EmployeeListLoad = (
  payload: SalaryPaymentEmployeeActionListPayload,
) => Promise<SalaryPaymentEmployeeActionListResolvePayload>;
type BulkCreate = (
  payload: SalaryPaymentBulkActionCreatePayload,
) => Promise<SalaryPaymentBulkActionCreateResolvePayload>;
type CashFeesGridLoad = () => Promise<CashActionFeesGridLoadResolvePayload>;

interface Props extends RouteComponentProps<{}> {
  user: UserState;
  employeeListLoad: EmployeeListLoad;
  bulkCreate: BulkCreate;
  cashFeesGridLoad: CashFeesGridLoad;
}

interface State {
  coreValues: BulkEditorCoreValues | null;

  // modal
  isEmployeeEditorOpen: boolean;
  isEmployeeSelectorOpen: boolean;
  employeeEditorTarget: Employee | null;

  // Looking for bulk
  step: 1 | 2;

  // employee list
  employeeList: Array<BulkEmployee>;
  isLoading: boolean;

  // error
  inputErrors: ApiResponseDataInputErrors | null;
  // processing
  isProcessing: boolean;
}

class EmployeesScreen extends React.PureComponent<Props, State> {
  state: State = {
    coreValues: null,
    isEmployeeEditorOpen: false,
    isEmployeeSelectorOpen: false,
    employeeEditorTarget: null,
    employeeList: [],
    isLoading: true,
    inputErrors: null,
    isProcessing: false,
    step: 1,
  };

  cashFeesGrid: CashFeesGridState = null;

  componentDidMount() {
    const { employeeListLoad, cashFeesGridLoad } = this.props;
    Promise.all([employeeListLoad({ status: 'active' }), cashFeesGridLoad()])
      .then(([{ data: employeeList }, { data: cashFeesGrid }]) => {
        this.cashFeesGrid = cashFeesGrid;
        this.setState({
          employeeList: employeeList.results.map(employee => this.processBulkEmployee({
            otherCharge: 0,
            fees: 0,
            ...employee,
          })),
          isLoading: false,
        });
      })
      .catch(actionRejectHandler());
  }

  processEmployee = (employee: Employee) => this.processBulkEmployee({
    ...employee,
    otherCharge: 0,
    fees: 0,
  });

  processBulkEmployee = (employee: BulkEmployee) => {
    const { user } = this.props;
    const fromTotal = this.cashFeesGrid!.withdraw.fromAmount[user!.preferredCurrency];
    const amount = (employee.salaryEdited || employee.salary) + employee.otherCharge;

    const found = fromTotal.find(({ min, max }) => amount >= min && amount <= max);
    const fees = found ? found.fees : 0;

    return {
      ...employee,
      fees,
    };
  };

  openEmployeeEditorForEdit = (editingEmployee: Employee) => {
    this.setState({
      isEmployeeEditorOpen: true,
      employeeEditorTarget: editingEmployee,
    });
  };

  openEmployeeEditorForCreate = () => {
    this.setState({
      isEmployeeEditorOpen: true,
      employeeEditorTarget: null,
    });
  };

  closeEmployeeEditor = () => {
    this.setState({ isEmployeeEditorOpen: false, employeeEditorTarget: null });
  };

  openEmployeeSelector = () => {
    this.setState({ isEmployeeSelectorOpen: true });
  };

  closeEmployeeSelector = () => {
    this.setState({ isEmployeeSelectorOpen: false });
  };

  onCoreValuesChange = (bulkValues: BulkEditorCoreValues) => {
    this.setState({ coreValues: bulkValues });
  };

  onEmployeeEdit = (employee: BulkEmployee) => {
    const { employeeList } = this.state;
    this.setState({
      employeeList: employeeList.map(e => (e.id === employee.id ? this.processBulkEmployee(employee) : e)),
    });
  };

  onEmployeeEditorSubmit = (employee: Employee) => {
    const { employeeList, employeeEditorTarget } = this.state;

    let newList = employeeList;
    if (employeeEditorTarget) {
      newList = newList.map(e => (e.id === employee.id ? this.processBulkEmployee({ ...e, ...employee }) : e));
    } else {
      newList = [this.processEmployee(employee), ...newList];
    }

    this.setState({
      isEmployeeSelectorOpen: false,
      isEmployeeEditorOpen: false,
      employeeEditorTarget: null,
      employeeList: newList,
    });
  };

  onEmployeeSelectorSelect = (employee: Employee) => {
    const { employeeList } = this.state;
    let newEmployeeList = employeeList;
    if (!employeeList.find(e => e.id === employee.id)) {
      newEmployeeList = [this.processEmployee(employee), ...newEmployeeList];
    }

    this.setState({
      isEmployeeSelectorOpen: false,
      employeeList: newEmployeeList,
    });
  };

  removeEmployeeFromList = (employee: BulkEmployee) => {
    const { employeeList } = this.state;
    this.setState({
      employeeList: employeeList.filter(e => e !== employee),
    });
  };

  onSubmitStep1 = handleFormSubmitEvent(() => {
    this.setState({ step: 2 });
  });

  onSubmitStep2Back = handleFormSubmitEvent(() => {
    this.setState({ step: 1 });
  });

  onSubmitStep2 = handleFormSubmitEvent(() => {
    const { coreValues, employeeList, isProcessing } = this.state;

    if (!employeeList || isProcessing) {
      return;
    }

    if (!coreValues) {
      toast.warn(t('Veuillez remplir tous les champs'));
      this.setState({ step: 2 });
      return;
    }

    const { communication, timeZone, scheduledAt } = coreValues;
    if (scheduledAt === null) {
      toast.warn(t('Veuillez entrer une date valide'));
      this.setState({ step: 2 });
      return;
    }

    const { user, bulkCreate } = this.props;
    const payload: SalaryPaymentBulkActionCreatePayload = {
      communication,
      scheduledAt,
      timeZone,
      currency: user!.preferredCurrency,
      employees: employeeList.map(({
        id, salary, otherCharge, fees, hourlyRate, salaryEdited,
      }) => ({
        id,
        salary: salaryEdited || salary,
        fees,
        otherCharge,
        hourlyRate,
      })),
    };

    this.setState({ isProcessing: true });
    bulkCreate(payload)
      .then(() => {
        toast.success('Paiement programmé avec succès');
        const { history } = this.props;
        history.push(RoutesUrls.salaryPaymentBulks);
      })
      .catch(
        actionRejectHandler({
          onInputInvalid: inputErrors => this.setState({ inputErrors }),
          always: () => this.setState({ isProcessing: false }),
        }),
      );
  });

  renderStep1() {
    const { step } = this.state;
    if (step !== 1) {
      return null;
    }

    const { user } = this.props;
    const { employeeList, isLoading, isProcessing } = this.state;

    if (isLoading) {
      return null;
    }

    return (
      <div>
        <div className="section">
          <button type="button" className="button is-pulled-right" onClick={this.openEmployeeSelector}>
            <span className="icon">
              <FontAwesomeIcon icon={faPlus} />
            </span>
            <span>{t('Ajouter un employé')}</span>
          </button>
          <h3 className="title">
            {t('Employés à payer')}
          </h3>
          <BulkEmployeeList
            list={employeeList}
            onRequestEdit={this.openEmployeeEditorForEdit}
            onRequestRemove={this.removeEmployeeFromList}
            onEmployeeEdit={this.onEmployeeEdit}
            currency={user!.preferredCurrency}
          />
        </div>

        <div className="section">
          <button
            type="button"
            className={classNames('button is-primary is-pulled-right', { 'is-loading': isProcessing })}
            onClick={this.onSubmitStep1}
          >
            <span>{t('Suivant')}</span>
            <span className="icon">
              <FontAwesomeIcon icon={faChevronCircleRight} />
            </span>
          </button>
        </div>
      </div>
    );
  }

  renderStep2() {
    const { step } = this.state;
    if (step !== 2) {
      return null;
    }

    const { inputErrors, isProcessing } = this.state;
    return (
      <div>
        <div className="section">
          <h3 className="title">
            {t('Programmation')}
          </h3>
          <form onSubmit={this.onSubmitStep2} id="bulk-create">
            <BulkCoreEditor onChange={this.onCoreValuesChange} inputErrors={inputErrors} />
          </form>
        </div>

        <div className="section">
          <button type="button" className={classNames('button')} onClick={this.onSubmitStep2Back}>
            <span className="icon">
              <FontAwesomeIcon icon={faChevronCircleLeft} />
            </span>
            <span>{t('Précédent')}</span>
          </button>
          <button
            type="submit"
            className={classNames('button is-primary is-pulled-right', { 'is-loading': isProcessing })}
            form="bulk-create"
          >
            <span>{t('Envoyer')}</span>
            <span className="icon">
              <FontAwesomeIcon icon={faPaperPlane} />
            </span>
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { user } = this.props;
    const {
      isEmployeeEditorOpen, isEmployeeSelectorOpen, employeeEditorTarget, employeeList, isLoading, step,
    } = this.state;

    return (
      <div className={classNames({ 'is-loading': isLoading })} id="salary-payment">
        <div className="container">
          <div className="section">
            <span className="is-pulled-right">
              {t(`Étape ${step}/2`)}
            </span>
            <h1 className="title">{t('Envoi de salaire')}</h1>
          </div>
          {this.renderStep1()}
          {this.renderStep2()}
        </div>

        <EmployeeEditorModal
          isOpen={isEmployeeEditorOpen}
          currency={user!.preferredCurrency}
          employee={employeeEditorTarget}
          onSubmit={this.onEmployeeEditorSubmit}
          onRequestClose={this.closeEmployeeEditor}
        />

        <EmployeeSelectorModal
          isOpen={isEmployeeSelectorOpen}
          onSelect={this.onEmployeeSelectorSelect}
          onRequestClose={this.closeEmployeeSelector}
          exclude={employeeList}
          onRequestCreate={this.openEmployeeEditorForCreate}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  employeeListLoad: EmployeeListLoad;
  bulkCreate: BulkCreate;
  cashFeesGridLoad: CashFeesGridLoad;
};
const mapDispatchToProps: MapDispatchToProps = (dispatch: Dispatch) => ({
  employeeListLoad: payload => new Promise((resolve, reject) => {
    dispatch(SalaryPaymentEmployeeActionCreators.list(payload, resolve, reject));
  }),
  bulkCreate: payload => new Promise((resolve, reject) => {
    dispatch(SalaryPaymentBulkActionCreators.create(payload, resolve, reject));
  }),
  cashFeesGridLoad: () => new Promise((resolve, reject) => {
    dispatch(CashFeesGridActionCreators.load(resolve, reject));
  }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeesScreen);
