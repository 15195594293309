import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { RoutesUrls } from '../../../../config/Routes';

const SignOut404Screen: React.FC<RouteComponentProps> = ({ location }: RouteComponentProps) => (
  <div>
    <Redirect
      to={{
        pathname: RoutesUrls.login,
        state: { from: location },
      }}
    />
  </div>
);

export default SignOut404Screen;
