import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import classNames from 'classnames';
import {
  SalaryPaymentBulkActionCreators,
  SalaryPaymentBulkActionListPayload,
  SalaryPaymentBulkActionListResolvePayload,
} from '../redux/salary-payment-bulk-actions';
import { t } from '../../../tools/functions/translation';
import { money } from '../../../tools/functions/money';
import { actionRejectHandler } from '../../../tools/functions/action-promise-handler';
import { ApiSalaryPaymentBulkListResponseData } from '../../../api/salary-payment-bulk-api';
import { formatDate } from '../../../tools/functions/date';
import { RoutesUrls } from '../../../config/Routes';

type BulkList = (payload: SalaryPaymentBulkActionListPayload) => Promise<SalaryPaymentBulkActionListResolvePayload>;

interface Props {
  bulkList: BulkList;
}

interface State {
  data: ApiSalaryPaymentBulkListResponseData | null;
}

class BulksScreen extends React.PureComponent<Props, State> {
  state: State = {
    data: null,
  };

  componentDidMount() {
    const { bulkList } = this.props;
    bulkList({})
      .then(({ data }) => {
        this.setState({
          data,
        });
      })
      .catch(actionRejectHandler());
  }

  renderBulkList() {
    const { data } = this.state;
    if (!data) {
      return null;
    }

    const content = data.results.map(item => (
      <tr className="" key={item.id}>
        <td>{formatDate(item.memberTimeZoneScheduledAt, 'DD MMM YYYY HH:mm')}</td>
        <td>{item.status}</td>
        <td>{item.communication}</td>
        <td>{item.number}</td>
        <td>{money(item.salaryTotal, item.currency)}</td>
        <td>{money(item.otherChargesTotal, item.currency)}</td>
        <td>{money(item.feesTotal, item.currency)}</td>
        <td>{money(item.total, item.currency)}</td>
        <td>
          <Link to={RoutesUrls.salaryPaymentBulkViewGen(item.id)} className="button is-small is-info">
            {t('Consulter')}
          </Link>
        </td>
      </tr>
    ));
    return (
      <div className="table-container">
        <table className="table is-striped is-fullwidth is-hoverable">
          <thead>
            <tr>
              <th>{t('Date')}</th>
              <th>{t('Statut')}</th>
              <th>{t('Communication')}</th>
              <th>{t('Nb. Employés')}</th>
              <th>{t('Salaire')}</th>
              <th>{t('Autres frais')}</th>
              <th>{t('Fees')}</th>
              <th>{t('Total')}</th>
              <th>{t('Détails')}</th>
            </tr>
          </thead>

          <tbody>{content}</tbody>
        </table>
      </div>
    );
  }

  render() {
    const { data } = this.state;
    return (
      <div className={classNames({ 'is-loading': data === null })}>
        <div className="container">
          <div className="section">
            <Link to={RoutesUrls.salaryPaymentBulkCreate} className="button  is-pulled-right">
              {t('Programmer un paiement')}
            </Link>
            <h1 className="title">{t('Paiements de salaire')}</h1>
            {this.renderBulkList()}
          </div>
        </div>
      </div>
    );
  }
}

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  bulkList: BulkList;
};
const mapDispatchToProps: MapDispatchToProps = (dispatch: Dispatch) => ({
  bulkList: payload => new Promise((resolve, reject) => {
    dispatch(SalaryPaymentBulkActionCreators.list(payload, resolve, reject));
  }),
});

export default connect(
  null,
  mapDispatchToProps,
)(BulksScreen);
