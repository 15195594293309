import { toast } from 'react-toastify';
import { ActionRejectDefaultArgument } from '../../redux/promises';
import { tErrorCode } from './translation';
import { ApiResponseDataInputErrors } from '../../api/shared-api-lib';

interface Arguments {
  onErrorCode?: (errorCode: string) => void;
  onInputInvalid?: (inputErrors: ApiResponseDataInputErrors) => void;
  onError?: (error: Error) => void;
  always?: () => void;
}

export function actionRejectHandler<T extends ActionRejectDefaultArgument>(args: Arguments = {}) {
  return (rejectArgument: T) => {
    const { error, errorCode, inputErrors } = rejectArgument;
    const {
      onError, onErrorCode, onInputInvalid, always,
    } = args;

    if (errorCode) {
      toast.warn(tErrorCode(errorCode));
      if (onErrorCode) {
        onErrorCode(errorCode);
      }
    } else if (error) {
      toast.error(error.message);
      if (onError) {
        onError(error);
      }
    }

    if (inputErrors && onInputInvalid) {
      onInputInvalid(inputErrors);
    }

    if (always) {
      always();
    }
  };
}
