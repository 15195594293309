import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan, faPen, faPencilAlt, faSave, faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { Employee } from '../../../api/salary-payment-employee-api';
import { t } from '../../../tools/functions/translation';
import { money } from '../../../tools/functions/money';
import { formatPhoneNumber } from '../../../tools/functions/formatters';
import { handleInputChangeEvent } from '../../../tools/functions/html-events-handler';

export interface BulkEmployee extends Employee {
  salaryEdited?: number;
  otherCharge: number;
  fees: number;
}

interface Props {
  employee: BulkEmployee;
  onEmployeeEdit: (employee: BulkEmployee) => void;
  onRequestEdit: (employee: BulkEmployee) => void;
  onRequestRemove: (employee: BulkEmployee) => void;
}

interface State {
  salaryValue: string;
  otherChargeValue: string;
  isEditingSalary: boolean;
  isEditingOtherCharge: boolean;
}

class BulkEmployeeLine extends React.PureComponent<Props, State> {
  state = {
    salaryValue: '',
    otherChargeValue: '',
    isEditingSalary: false,
    isEditingOtherCharge: false,
  };

  handleSalaryChange = handleInputChangeEvent((salaryValue: string) => this.setState({ salaryValue }));

  handleOtherChargeChange = handleInputChangeEvent((otherChargeValue: string) => this.setState({ otherChargeValue }));

  enableSalaryEdit = () => {
    const { employee } = this.props;
    this.setState({
      isEditingSalary: true,
      salaryValue: String((employee.salaryEdited || employee.salary) / 100),
    });
  };

  saveSalaryEdit = () => {
    const { salaryValue } = this.state;
    if (!salaryValue) {
      toast.warn(t('Veillez entrer un montant valide'));
      return;
    }

    const salaryEdited = parseInt(salaryValue, 10);
    if (Number.isNaN(salaryEdited)) {
      toast.warn(t('Veillez entrer un montant valide'));
      return;
    }

    const { onEmployeeEdit, employee } = this.props;
    onEmployeeEdit({
      ...employee,
      salaryEdited: salaryEdited * 100,
    });

    this.setState({
      isEditingSalary: false,
      salaryValue: '',
    });
  };

  cancelSalaryEdit = () => {
    this.setState({
      isEditingSalary: false,
      salaryValue: '',
    });
  };

  enableOtherChargeEdit = () => {
    const { employee } = this.props;
    this.setState({
      isEditingOtherCharge: true,
      otherChargeValue: String(employee.otherCharge / 100),
    });
  };

  saveOtherChargeEdit = () => {
    const { otherChargeValue } = this.state;
    if (!otherChargeValue) {
      toast.warn(t('Veillez entrer un montant valide'));
      return;
    }

    const otherCharge = parseInt(otherChargeValue, 10);
    if (Number.isNaN(otherCharge)) {
      toast.warn(t('Veillez entrer un montant valide'));
      return;
    }

    const { onEmployeeEdit, employee } = this.props;
    onEmployeeEdit({
      ...employee,
      otherCharge: otherCharge * 100,
    });

    this.setState({
      isEditingOtherCharge: false,
      otherChargeValue: '',
    });
  };

  cancelOtherChargeEdit = () => {
    this.setState({
      isEditingOtherCharge: false,
      otherChargeValue: '',
    });
  };

  onRequestEdit = () => {
    const { employee, onRequestEdit } = this.props;
    onRequestEdit(employee);
  };

  onRequestRemove = () => {
    const { employee, onRequestRemove } = this.props;
    onRequestRemove(employee);
  };

  render() {
    const { employee } = this.props;

    const {
      phoneCountryPrefix,
      phoneNumber,
      lastName,
      currency,
      otherCharge,
      id,
      email,
      firstName,
      salary,
      salaryEdited,
      fees,
    } = employee;

    const total = (salaryEdited || salary) + otherCharge + fees;

    const {
      salaryValue, otherChargeValue, isEditingSalary, isEditingOtherCharge,
    } = this.state;

    let salaryCell;
    if (isEditingSalary) {
      salaryCell = (
        <div>
          <input className="input" value={salaryValue} onChange={this.handleSalaryChange} />
          <button
            type="button"
            className="button is-pulled-right is-success has-margin"
            onClick={this.saveSalaryEdit}
            title={t('Sauver')}
          >
            <span className="icon is-small is-success">
              <FontAwesomeIcon icon={faSave} />
            </span>
          </button>
          <button
            type="button"
            className="button is-pulled-left is-danger has-margin"
            onClick={this.cancelSalaryEdit}
            title={t('Annuler')}
          >
            <span className="icon is-small is-success">
              <FontAwesomeIcon icon={faBan} />
            </span>
          </button>
        </div>
      );
    } else {
      salaryCell = (
        <span>
          {money(salaryEdited || salary, currency)}
          <button
            type="button"
            className="button is-small no-background"
            onClick={this.enableSalaryEdit}
            title={t('Modifier')}
          >
            <span className="icon is-small">
              <FontAwesomeIcon icon={faPencilAlt} />
            </span>
          </button>
        </span>
      );
    }

    let otherChargeCell;
    if (isEditingOtherCharge) {
      otherChargeCell = (
        <div>
          <input className="input" value={otherChargeValue} onChange={this.handleOtherChargeChange} />
          <button
            type="button"
            className="button is-pulled-right is-success has-margin"
            onClick={this.saveOtherChargeEdit}
          >
            <span className="icon is-small is-success">
              <FontAwesomeIcon icon={faSave} />
            </span>
          </button>
          <button
            type="button"
            className="button is-pulled-left is-danger has-margin"
            onClick={this.cancelOtherChargeEdit}
          >
            <span className="icon is-small is-danger">
              <FontAwesomeIcon icon={faBan} />
            </span>
          </button>
        </div>
      );
    } else {
      otherChargeCell = (
        <span>
          {money(otherCharge, currency)}
          <button type="button" className="button is-small no-background" onClick={this.enableOtherChargeEdit}>
            <span className="icon is-small">
              <FontAwesomeIcon icon={faPencilAlt} />
            </span>
          </button>
        </span>
      );
    }

    return (
      <tr key={id}>
        <td>{firstName}</td>
        <td>{lastName}</td>
        <td>{!!email && email}</td>
        <td>{!!phoneNumber && formatPhoneNumber(phoneCountryPrefix, phoneNumber)}</td>
        <td>
          <div className="column-editable">{salaryCell}</div>
        </td>
        <td>
          <div className="column-editable">{otherChargeCell}</div>
        </td>
        <td>{money(fees, currency)}</td>
        <td>{money(total, currency)}</td>
        <td className="is-paddingless">
          <button type="button" className="button is-info is-small has-margin" onClick={this.onRequestEdit}>
            <span className="icon">
              <FontAwesomeIcon icon={faPen} />
            </span>
            <span>{t('Modifier')}</span>
          </button>
          <button type="button" className="button is-small is-danger has-margin" onClick={this.onRequestRemove}>
            <span className="icon">
              <FontAwesomeIcon icon={faTrash} />
            </span>
            <span>{t('Retirer')}</span>
          </button>
        </td>
      </tr>
    );
  }
}

export default BulkEmployeeLine;
