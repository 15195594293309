import React from 'react';
import { RouteComponentProps } from 'react-router';
import Particles, { IParticlesParams } from 'react-particles-js';
import LoginForm from '../components/LoginForm';
import SecondFactorAuthModal, { SecondFactorAuthData } from '../components/SecondFactorAuthModal';

interface Props extends RouteComponentProps {}

interface State {
  isModalOpen: boolean;
  sfaData: SecondFactorAuthData | null;
}

class LoginScreen extends React.PureComponent<Props, State> {
  state: State = {
    isModalOpen: false,
    sfaData: null,
  };

  particles: IParticlesParams = {
    particles: {
      number: {
        value: 50,
      },
      size: {
        value: 3,
      },
    },
    interactivity: {
      events: {
        onhover: {
          enable: true,
          mode: 'repulse',
        },
      },
    },
  };

  onRequestSecondFactorAuth = (sfaData: SecondFactorAuthData) => {
    this.setState({ sfaData, isModalOpen: true });
  };

  onRequestModalClose = () => {
    this.setState({ sfaData: null, isModalOpen: false });
  };

  render() {
    const { location } = this.props;
    const redirectUrl = location.state && location.state.from;

    const { isModalOpen, sfaData } = this.state;

    return (
      <section id="login" className="section hero is-fullheight">
        <Particles className="particles" params={this.particles} />
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <LoginForm redirectUrl={redirectUrl} onRequestSecondFactorAuth={this.onRequestSecondFactorAuth} />
            </div>
          </div>
        </div>
        <SecondFactorAuthModal isOpen={isModalOpen} data={sfaData} onRequestClose={this.onRequestModalClose} />
      </section>
    );
  }
}

export default LoginScreen;
