import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import classNames from 'classnames';
import {
  SalaryPaymentEmployeeActionAddPayload,
  SalaryPaymentEmployeeActionAddResolvePayload,
  SalaryPaymentEmployeeActionCreators,
  SalaryPaymentEmployeeActionEditPayload,
  SalaryPaymentEmployeeActionEditResolvePayload,
} from '../redux/salary-payment-employee-actions';
import { handleFormSubmitEvent, handleInputChangeEvent } from '../../../tools/functions/html-events-handler';
import { Employee } from '../../../api/salary-payment-employee-api';
import { t } from '../../../tools/functions/translation';
import { actionRejectHandler } from '../../../tools/functions/action-promise-handler';
import Modal from '../../shared/components/Modal';
import PhoneCountrySelect from '../../shared/components/PhoneCountrySelect';
import { ApiResponseDataInputErrors } from '../../../api/shared-api-lib';
import { extractInputErrors } from '../../shared/tools/input-errors-helper';
import InputErrorsText from '../../shared/components/form/InputErrorsText';

type EmployeeAdd = (
  payload: SalaryPaymentEmployeeActionAddPayload,
) => Promise<SalaryPaymentEmployeeActionAddResolvePayload>;
type EmployeeEdit = (
  payload: SalaryPaymentEmployeeActionEditPayload,
) => Promise<SalaryPaymentEmployeeActionEditResolvePayload>;

interface Props {
  employeeAdd: EmployeeAdd;
  employeeEdit: EmployeeEdit;
  employee: Employee | null;
  currency: string;
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit?: (employee: Employee) => void;
}

interface State {
  // inputs
  email: string;
  phoneCountry: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  salary: string;
  hourlyRate: string;

  // error
  inputErrors: ApiResponseDataInputErrors | null;
  // processing
  isProcessing: boolean;

  // prev props
  prevEmployee: Employee | null;
}

class EmployeeEditorModal extends React.PureComponent<Props, State> {
  static getDerivedStateFromProps({ employee }: Props, prevState: State): Partial<State> {
    const { prevEmployee } = prevState; // https://github.com/yannickcr/eslint-plugin-react/issues/1759

    if (employee !== prevEmployee) {
      if (employee === null) {
        return {
          prevEmployee: null,
          email: '',
          phoneCountry: '',
          phoneNumber: '',
          firstName: '',
          lastName: '',
          salary: '',
          hourlyRate: '',
          isProcessing: false,
          inputErrors: null,
        };
      }

      return {
        prevEmployee: employee,
        email: employee.email || '',
        phoneCountry: employee.phoneCountry || '',
        phoneNumber: employee.phoneNumber || '',
        firstName: employee.firstName,
        lastName: employee.lastName,
        salary: typeof employee.salary === 'number' ? String(employee.salary / 100) : '',
        hourlyRate: typeof employee.hourlyRate === 'number' ? String(employee.hourlyRate / 100) : '',
        isProcessing: false,
        inputErrors: null,
      };
    }

    return {};
  }

  state: State = {
    email: '',
    phoneCountry: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    salary: '',
    hourlyRate: '',

    isProcessing: false,
    inputErrors: null,

    prevEmployee: null,
  };

  handleEmail = handleInputChangeEvent((email: string) => this.setState({ email }));

  handlePhoneCountry = (phoneCountry: string) => this.setState({ phoneCountry });

  handlePhoneNumber = handleInputChangeEvent((phoneNumber: string) => this.setState({ phoneNumber }));

  handleFirstName = handleInputChangeEvent((firstName: string) => this.setState({ firstName }));

  handleLastName = handleInputChangeEvent((lastName: string) => this.setState({ lastName }));

  handleSalary = handleInputChangeEvent((value: string) => this.setState({ salary: this.filterNumber(value) }));

  handleHourlyRate = handleInputChangeEvent((value: string) => this.setState({ hourlyRate: this.filterNumber(value) }));

  filterNumber = (value: string) => value.replace('/[^\\d]/g', '');

  parseAmount = (value: string) => {
    const amountInt = parseInt(value, 10);
    if (Number.isNaN(amountInt)) {
      return 0;
    }
    return amountInt * 100;
  };

  onRequestClose = () => {
    const { onRequestClose } = this.props;
    onRequestClose();
  };

  onSubmit = handleFormSubmitEvent(() => {
    const {
      email, phoneCountry, phoneNumber, firstName, lastName, salary, hourlyRate, isProcessing,
    } = this.state;
    const {
      currency, employee, employeeAdd, employeeEdit,
    } = this.props;

    if (isProcessing) {
      return;
    }

    const payload: SalaryPaymentEmployeeActionAddPayload = {
      firstName,
      lastName,
      currency,
      email: email || null,
      phoneCountry: phoneCountry || null,
      phoneNumber: phoneNumber || null,
      salary: this.parseAmount(salary),
      hourlyRate: this.parseAmount(hourlyRate),
    };

    this.setState({ isProcessing: true });

    if (employee) {
      employeeEdit({
        employeeId: employee.id,
        ...payload,
      })
        .then(({ data }) => {
          const { inputErrors, ...employeeData } = data;
          const { onSubmit } = this.props;
          if (onSubmit) {
            onSubmit(employeeData);
          }
        })
        .catch(
          actionRejectHandler({
            onInputInvalid: inputErrors => this.setState({ inputErrors }),
          }),
        )
        .finally(() => this.setState({ isProcessing: false }));
      return;
    }

    employeeAdd(payload)
      .then(({ data }) => {
        const { inputErrors, ...employeeData } = data;
        const { onSubmit } = this.props;
        if (onSubmit) {
          onSubmit(employeeData);
        }
      })
      .catch(
        actionRejectHandler({
          onInputInvalid: inputErrors => this.setState({ inputErrors }),
        }),
      )
      .finally(() => this.setState({ isProcessing: false }));
  });

  render() {
    const { isOpen, employee, onRequestClose } = this.props;
    const {
      email,
      phoneCountry,
      phoneNumber,
      firstName,
      lastName,
      salary,
      hourlyRate,
      isProcessing,
      inputErrors,
    } = this.state;

    const title = employee ? t('Modifier un employé') : t('Ajouter un employé');
    const submitText = employee ? t('Sauver') : t('Créer');

    const emailErrors = extractInputErrors('email', inputErrors);
    const phoneNumberErrors = extractInputErrors('phoneNumber', inputErrors);
    const firstNameErrors = extractInputErrors('firstName', inputErrors);
    const lastNameErrors = extractInputErrors('lastName', inputErrors);
    const salaryErrors = extractInputErrors('salary', inputErrors);
    const hourlyRateErrors = extractInputErrors('hourlyRate', inputErrors);

    return (
      <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
        <div className="modal-card">
          <header className="modal-card-head">
            <h2 className="modal-card-title">{title}</h2>
            <button type="button" className="delete" aria-label="close" onClick={this.onRequestClose} />
          </header>

          <section className="modal-card-body">
            <form onSubmit={this.onSubmit} id="employee-editor">
              <div className="field">
                <label className="label" htmlFor="email">
                  {t('Adresse email')}
                </label>
                <div className="control">
                  <input
                    type="text"
                    placeholder="L'adresse mail de l'employé"
                    className={classNames('input', { 'is-danger': emailErrors })}
                    value={email}
                    id="email"
                    onChange={this.handleEmail}
                  />
                  <InputErrorsText inputErrors={emailErrors} />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="phone">
                  {t('Téléphone')}
                </label>
                <div className="control">
                  <div className="field has-addons">
                    <div className="control">
                      <PhoneCountrySelect value={phoneCountry} onChange={this.handlePhoneCountry} />
                    </div>
                    <p className="control is-expanded">
                      <input
                        type="text"
                        className={classNames('input', { 'is-danger': phoneNumberErrors })}
                        value={phoneNumber}
                        id="phone"
                        onChange={this.handlePhoneNumber}
                      />
                      <InputErrorsText inputErrors={phoneNumberErrors} />
                    </p>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="firstName">
                  {t('Prénom')}
                </label>
                <div className="control">
                  <input
                    type="text"
                    placeholder="Prénom de l'employé"
                    className={classNames('input', { 'is-danger': firstNameErrors })}
                    value={firstName}
                    id="firstName"
                    onChange={this.handleFirstName}
                  />
                  <InputErrorsText inputErrors={firstNameErrors} />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="lastName">
                  {t('Nom de famille')}
                </label>
                <div className="control">
                  <input
                    type="text"
                    placeholder="Nom de famille de l'employé"
                    className={classNames('input', { 'is-danger': lastNameErrors })}
                    value={lastName}
                    id="lastName"
                    onChange={this.handleLastName}
                  />
                  <InputErrorsText inputErrors={lastNameErrors} />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="salary">
                  {t('Salaire')}
                </label>
                <div className="control">
                  <input
                    type="text"
                    placeholder="Salaire de l'employé"
                    className={classNames('input', { 'is-danger': salaryErrors })}
                    value={salary}
                    id="salary"
                    onChange={this.handleSalary}
                  />
                  <InputErrorsText inputErrors={salaryErrors} />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="hourlyRate">
                  {t('Salaire horaire')}
                </label>
                <div className="control">
                  <input
                    type="text"
                    placeholder="Salaire horaire de l'employé"
                    className={classNames('input', { 'is-danger': hourlyRateErrors })}
                    value={hourlyRate}
                    id="hourlyRate"
                    onChange={this.handleHourlyRate}
                  />
                  <InputErrorsText inputErrors={hourlyRateErrors} />
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <div className="column is-half">
              <button type="button" className="button no-background is-fullwidth" onClick={this.onRequestClose}>
                {t('Retour')}
              </button>
            </div>
            <div className="column is-half">
              <button
                type="submit"
                className={classNames('button btn-modal no-border is-fullwidth', { 'is-loading': isProcessing })}
                disabled={isProcessing}
                form="employee-editor"
              >
                {t(submitText)}
              </button>
            </div>
          </footer>
        </div>
      </Modal>
    );
  }
}

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  employeeAdd: EmployeeAdd;
  employeeEdit: EmployeeEdit;
};
const mapDispatchToProps: MapDispatchToProps = (dispatch: Dispatch) => ({
  employeeAdd: payload => new Promise((resolve, reject) => {
    dispatch(SalaryPaymentEmployeeActionCreators.add(payload, resolve, reject));
  }),
  employeeEdit: payload => new Promise((resolve, reject) => {
    dispatch(SalaryPaymentEmployeeActionCreators.edit(payload, resolve, reject));
  }),
});

export default connect(
  null,
  mapDispatchToProps,
)(EmployeeEditorModal);
