import storage from 'redux-persist/lib/storage';
import { PersistConfig } from 'redux-persist/es/types';

const persistConfig: PersistConfig = {
  storage,
  version: 1,
  key: 'root',
  whitelist: ['device', 'sessions', 'auth'],
};

export default persistConfig;
