import { call, put, takeEvery } from 'redux-saga/effects';
import { USER_ACTION_LOAD, UserActionCreators, UserActionLoad } from './user-actions';
import { apiMe, ApiMeResponse } from '../../api/me-api';
import { apiCallWrapper } from '../../tools/functions/api-call-handler';

function* userLoad({ resolve, reject }: UserActionLoad) {
  try {
    const response: ApiMeResponse = yield call(apiCallWrapper, apiMe);

    const { success, data, errorCode } = response;
    if (success) {
      yield put(UserActionCreators.set(data));
      resolve({});
      return;
    }

    reject({ errorCode });
  } catch (error) {
    reject({ error });
  }
}

export default function* userSaga() {
  yield takeEvery(USER_ACTION_LOAD, userLoad);
}
