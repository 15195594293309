import React from 'react';
import { t } from '../../../tools/functions/translation';
import BulkEmployeeLine, { BulkEmployee } from './BulkEmployeeLine';
import { money } from '../../../tools/functions/money';

interface Props {
  list: Array<BulkEmployee>;
  onEmployeeEdit: (employee: BulkEmployee) => void;
  onRequestEdit: (employee: BulkEmployee) => void;
  onRequestRemove: (employee: BulkEmployee) => void;
  currency: string;
}

class BulkEmployeeList extends React.PureComponent<Props> {
  render() {
    const {
      currency, list, onRequestEdit, onRequestRemove, onEmployeeEdit,
    } = this.props;

    const totalSalary = list.reduce((acc, { salary, salaryEdited }) => acc + (salaryEdited || salary), 0);
    const totalOtherCharge = list.reduce((acc, { otherCharge }) => acc + otherCharge, 0);
    const totalFees = list.reduce((acc, { fees }) => acc + fees, 0);
    const total = totalSalary + totalOtherCharge + totalFees;

    return (
      <div className="table-container">
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>{t('Prénom')}</th>
              <th>{t('Nom')}</th>
              <th>{t('Email')}</th>
              <th>{t('Téléphone')}</th>
              <th>{t('Salaire')}</th>
              <th>{t('Autre charges')}</th>
              <th>{t('Fees')}</th>
              <th>{t('Total')}</th>
              <th>{t('Action')}</th>
            </tr>
          </thead>
          <tbody>
            {list.map(item => (
              <BulkEmployeeLine
                key={item.id}
                employee={item}
                onRequestEdit={onRequestEdit}
                onRequestRemove={onRequestRemove}
                onEmployeeEdit={onEmployeeEdit}
              />
            ))}
            <tr>
              <td colSpan={3} />
              <th>{t('Total')}</th>
              <td>{money(totalSalary, currency)}</td>
              <td>{money(totalOtherCharge, currency)}</td>
              <td>{money(totalFees, currency)}</td>
              <td>
                <strong>{money(total, currency)}</strong>
              </td>
              <td />
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default BulkEmployeeList;
