import { ActionReject, ActionResolve } from '../../../redux/promises';
import { ApiLoginResponseData, ApiLoginSecondFactorAuthResponseData } from '../../../api/login-api';

// Types

export const LOGIN_ACTION_SUBMIT = 'LOGIN_ACTION_SUBMIT';
export const LOGIN_SECOND_FACTOR_AUTH_ACTION_SUBMIT = 'LOGIN_SECOND_FACTOR_AUTH_ACTION_SUBMIT';

// Actions creators definitions

// -- login

interface LoginActionSubmitPayloadBase {
  type: string;
  password: string;
  redirectUrl?: string | null;
}

interface LoginActionSubmitPayloadEmail extends LoginActionSubmitPayloadBase {
  type: 'email';
  email: string;
}

interface LoginActionSubmitPayloadPhone extends LoginActionSubmitPayloadBase {
  type: 'phone';
  phone: {
    country: string;
    number: string;
  };
}

export type LoginActionSubmitPayload = LoginActionSubmitPayloadEmail | LoginActionSubmitPayloadPhone;

export interface LoginActionSubmitResolvePayload {
  data: ApiLoginResponseData;
}

export interface LoginActionSubmit {
  type: typeof LOGIN_ACTION_SUBMIT;
  payload: LoginActionSubmitPayload;
  resolve: ActionResolve<LoginActionSubmitResolvePayload>;
  reject: ActionReject;
}

// -- login second factor auth

export interface LoginSecondFactorAuthActionSubmitPayload {
  secondFactorAuthId: string;
  code: string;
  loginActionSubmitPayload: LoginActionSubmitPayload;
}

export interface LoginSecondFactorAuthActionSubmitResolvePayload {
  data: ApiLoginSecondFactorAuthResponseData;
}

export interface LoginSecondFactorAuthActionSubmit {
  type: typeof LOGIN_SECOND_FACTOR_AUTH_ACTION_SUBMIT;
  payload: LoginSecondFactorAuthActionSubmitPayload;
  resolve: ActionResolve<LoginSecondFactorAuthActionSubmitResolvePayload>;
  reject: ActionReject;
}

// -- enum

export type LoginActions = LoginActionSubmit | LoginSecondFactorAuthActionSubmit;

// Action creators

export const LoginActionCreators = {
  submit: (
    payload: LoginActionSubmitPayload,
    resolve: ActionResolve<LoginActionSubmitResolvePayload>,
    reject: ActionReject,
  ): LoginActionSubmit => ({
    type: LOGIN_ACTION_SUBMIT,
    payload,
    resolve,
    reject,
  }),
  secondFactorAuthSubmit: (
    payload: LoginSecondFactorAuthActionSubmitPayload,
    resolve: ActionResolve<LoginSecondFactorAuthActionSubmitResolvePayload>,
    reject: ActionReject,
  ): LoginSecondFactorAuthActionSubmit => ({
    type: LOGIN_SECOND_FACTOR_AUTH_ACTION_SUBMIT,
    payload,
    resolve,
    reject,
  }),
};
