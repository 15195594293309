import React from 'react';

// prettier-ignore
export const handleInputChangeEvent = (callback: (value: string) => void) => (
  (e: React.ChangeEvent<HTMLInputElement>) => callback(e.target.value)
);

// prettier-ignore
export const handleSelectChangeEvent = (callback: (value: string) => void) => (
  (e: React.ChangeEvent<HTMLSelectElement>) => callback(e.target.value)
);

export const handleFormSubmitEvent = (callback: () => void) => (e: React.FormEvent) => {
  e.preventDefault();
  callback();
};
