import React from 'react';
import { connect } from 'react-redux';
import { ServerErrorCodes } from 'koosmik-common/utils';
import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import {
  LoginActionCreators,
  LoginActionSubmitPayload,
  LoginSecondFactorAuthActionSubmitPayload,
  LoginSecondFactorAuthActionSubmitResolvePayload,
} from '../redux/login-actions';
import { handleInputChangeEvent } from '../../../tools/functions/html-events-handler';
import Modal from '../../shared/components/Modal';
import { t } from '../../../tools/functions/translation';
import { actionRejectHandler } from '../../../tools/functions/action-promise-handler';

type LoginSecondFactorAuthSubmit = (
  payload: LoginSecondFactorAuthActionSubmitPayload,
) => Promise<LoginSecondFactorAuthActionSubmitResolvePayload>;

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  loginSecondFactorAuthSubmit: LoginSecondFactorAuthSubmit;
  data: SecondFactorAuthData | null;
}

export interface SecondFactorAuthData {
  secondFactorAuthId: string;
  loginActionSubmitPayload: LoginActionSubmitPayload;
}

interface State {
  code: string;
  isProcessing: boolean;
}

class SecondFactorAuthModal extends React.PureComponent<Props, State> {
  state: State = {
    code: '',
    isProcessing: false,
  };

  handleCode = handleInputChangeEvent((code: string) => this.setState({ code: this.filterCode(code) }));

  filterCode = (code: string) => code.replace(/[^\d]/g, '');

  onSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();

    const { data, loginSecondFactorAuthSubmit } = this.props;
    const { code } = this.state;

    if (!data) {
      toast.error('no data defined');
      return;
    }

    this.setState({ isProcessing: true });

    const payload: LoginSecondFactorAuthActionSubmitPayload = {
      code,
      ...data,
    };
    loginSecondFactorAuthSubmit(payload).catch(
      actionRejectHandler({
        onErrorCode: (errorCode) => {
          if (errorCode === ServerErrorCodes.SMS_VERIFICATION_TOO_MANY_ERRORS) {
            const { onRequestClose } = this.props;
            onRequestClose();
          }
        },
        always: () => this.setState({ isProcessing: false }),
      }),
    );
  };

  render() {
    const { isOpen, onRequestClose } = this.props;
    const { code, isProcessing } = this.state;

    return (
      <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
        <div className="modal-content" id="login-second-factor-auth">
          <div className="box">
            <p>{t('Un sms a être envoyé à votre appareil. Veillez entrez ici le code reçu.')}</p>
            <form onSubmit={this.onSubmit}>
              <div className="field">
                <div className="control">
                  <input
                    type="test"
                    className="input"
                    value={code}
                    id="code"
                    minLength={6}
                    maxLength={6}
                    placeholder={t('Code reçu')}
                    onChange={this.handleCode}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column is-half">
                  <button type="button" className="button is-fullwidth" onClick={onRequestClose}>
                    {t('Retour')}
                  </button>
                </div>
                <div className="column is-half">
                  <button
                    type="submit"
                    className={classNames('button is-primary no-border is-fullwidth', { 'is-loading': isProcessing })}
                  >
                    {t('Soumettre')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  loginSecondFactorAuthSubmit: LoginSecondFactorAuthSubmit;
};
const mapDispatchToProps: MapDispatchToProps = dispatch => ({
  loginSecondFactorAuthSubmit: payload => new Promise((resolve, reject) => {
    dispatch(LoginActionCreators.secondFactorAuthSubmit(payload, resolve, reject));
  }),
});

export default connect(
  null,
  mapDispatchToProps,
)(SecondFactorAuthModal);
