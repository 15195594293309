import React from 'react';
import { SignOutRoutes } from '../../../config/Routes';

const SignOutComponent: React.FC = () => (
  <div>
    <SignOutRoutes />
  </div>
);

export default SignOutComponent;
