import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan, faCheck, faPen, faPlus,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import RootState from '../../../redux/RootState';
import UserState from '../../../redux/user/user-state';
import {
  SalaryPaymentEmployeeActionCreators,
  SalaryPaymentEmployeeActionListPayload,
  SalaryPaymentEmployeeActionListPayloadStatus,
  SalaryPaymentEmployeeActionListResolvePayload,
  SalaryPaymentEmployeeActionPatchPayload,
  SalaryPaymentEmployeeActionPatchResolvePayload,
} from '../redux/salary-payment-employee-actions';
import { Employee } from '../../../api/salary-payment-employee-api';
import EmployeeEditorModal from '../components/EmployeeEditorModal';
import { t } from '../../../tools/functions/translation';
import { money } from '../../../tools/functions/money';
import { actionRejectHandler } from '../../../tools/functions/action-promise-handler';
import { formatPhoneNumber } from '../../../tools/functions/formatters';

type EmployeeList = (
  payload: SalaryPaymentEmployeeActionListPayload,
) => Promise<SalaryPaymentEmployeeActionListResolvePayload>;

type EmployeePatch = (
  payload: SalaryPaymentEmployeeActionPatchPayload,
) => Promise<SalaryPaymentEmployeeActionPatchResolvePayload>;

interface Props {
  user: UserState;
  employeeList: EmployeeList;
  employeePatch: EmployeePatch;
}

interface State {
  isEditorOpen: boolean;
  editorTarget: Employee | null;

  // employee employeeListLoad
  status: SalaryPaymentEmployeeActionListPayloadStatus;
  list: Array<Employee> | null;
  isLoading: boolean;
}

class EmployeesScreen extends React.PureComponent<Props, State> {
  state: State = {
    isEditorOpen: false,
    editorTarget: null,
    status: 'active',
    list: null,
    isLoading: true,
  };

  componentDidMount() {
    const { employeeList } = this.props;
    const { status } = this.state;
    employeeList({ status })
      .then(({ data }) => {
        this.setState({
          list: data.results,
          isLoading: false,
        });
      })
      .catch(actionRejectHandler())
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  openEditorForEdit = (editingEmployee: Employee) => {
    this.setState({
      isEditorOpen: true,
      editorTarget: editingEmployee,
    });
  };

  openEditorForCreate = () => {
    this.setState({
      isEditorOpen: true,
      editorTarget: null,
    });
  };

  closeEditor = () => {
    this.setState({ isEditorOpen: false, editorTarget: null });
  };

  showActive = (e: React.MouseEvent) => {
    e.preventDefault();
    this.loadList('active');
  };

  showInactive = (e: React.MouseEvent) => {
    e.preventDefault();
    this.loadList('inactive');
  };

  showAll = (e: React.MouseEvent) => {
    e.preventDefault();
    this.loadList('all');
  };

  loadList = (status: SalaryPaymentEmployeeActionListPayloadStatus) => {
    const { employeeList } = this.props;

    this.setState({ status, isLoading: true });
    employeeList({ status })
      .then(({ data }) => {
        this.setState({
          list: data.results,
          isLoading: false,
        });
      })
      .catch(actionRejectHandler())
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  toggleEmployeeStatus(item: Employee) {
    const { employeePatch } = this.props;
    employeePatch({
      employeeId: item.id,
      disabled: !item.disabled,
    }).then(({ data }) => {
      const { list } = this.state;
      if (!list) {
        return;
      }

      this.setState({
        list: list.map(e => (e.id === data.id ? data : e)),
      });
    });
  }

  onEditorSubmit = (employee: Employee) => {
    const { list, editorTarget } = this.state;
    if (!list) {
      this.closeEditor();
      return;
    }

    let newList = list;
    if (editorTarget) {
      newList = newList.map(e => (e.id === employee.id ? employee : e));
    } else {
      newList = [employee, ...newList];
    }

    this.setState({
      isEditorOpen: false,
      editorTarget: null,
      list: newList,
    });
  };

  renderTabs() {
    const { status } = this.state;
    return (
      <div className="tabs is-medium">
        <ul>
          <li className={classNames({ 'is-active': status === 'active' })}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={this.showActive}>
              {t('Activés')}
            </a>
          </li>
          <li className={classNames({ 'is-active': status === 'inactive' })}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={this.showInactive}>
              {t('Désactivés')}
            </a>
          </li>
          <li className={classNames({ 'is-active': status === 'all' })}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={this.showAll}>
              {t('Tous')}
            </a>
          </li>
        </ul>
      </div>
    );
  }

  renderEmployeeToggleButton(item: Employee) {
    let btnClass = 'is-success';
    let btnTitle = t('Activé');
    let icon = faCheck;

    if (item.disabled) {
      btnClass = 'is-danger';
      btnTitle = t('Désactivé');
      icon = faBan;
    }

    return (
      <button
        type="button"
        className={classNames('button is-small', btnClass)}
        onClick={() => this.toggleEmployeeStatus(item)}
        title={btnTitle}
        aria-label={btnTitle}
        role="checkbox"
        aria-checked={!item.disabled}
      >
        <span className="icon is-small">
          <FontAwesomeIcon icon={icon} inverse />
        </span>
      </button>
    );
  }

  renderEmployeeList() {
    const { list, isLoading } = this.state;
    if (!list) {
      return null;
    }

    const content = list.map((item) => {
      const {
        phoneCountryPrefix, phoneNumber, lastName, currency, hourlyRate, id, email, firstName, salary,
      } = item;
      return (
        <tr key={id}>
          <td>{firstName}</td>
          <td>{lastName}</td>
          <td>{!!email && email}</td>
          <td>{!!phoneNumber && formatPhoneNumber(phoneCountryPrefix, phoneNumber)}</td>
          <td>{money(salary, currency)}</td>
          <td>{!!hourlyRate && money(hourlyRate, currency)}</td>
          <td>{this.renderEmployeeToggleButton(item)}</td>
          <td>
            <button type="button" className="button is-info is-small" onClick={() => this.openEditorForEdit(item)}>
              <span className="icon">
                <FontAwesomeIcon icon={faPen} />
              </span>
              <span>{t('Modifier')}</span>
            </button>
          </td>
        </tr>
      );
    });
    return (
      <div className={classNames('table-container', { 'is-loading': isLoading })}>
        <table className="table is-striped is-fullwidth is-hoverable">
          <thead>
            <tr>
              <th>{t('Prénom')}</th>
              <th>{t('Nom')}</th>
              <th>{t('Email')}</th>
              <th>{t('Téléphone')}</th>
              <th>{t('Salaire')}</th>
              <th>{t('Taux horaire')}</th>
              <th>{t('Activé')}</th>
              <th>{t('Action')}</th>
            </tr>
          </thead>
          <tbody>{content}</tbody>
        </table>
      </div>
    );
  }

  render() {
    const { user } = this.props;
    const { isEditorOpen, editorTarget, list } = this.state;

    return (
      <div className={classNames({ 'is-loading': list === null })}>
        <div className="container">
          <div className="section">
            <button type="button" className="button is-pulled-right" onClick={this.openEditorForCreate}>
              <span className="icon">
                <FontAwesomeIcon icon={faPlus} />
              </span>
              <span>{t('Ajouter un employé')}</span>
            </button>
            <h1 className="title">{t('Employés')}</h1>
            {this.renderTabs()}
            {this.renderEmployeeList()}
          </div>
        </div>

        <EmployeeEditorModal
          isOpen={isEditorOpen}
          currency={user!.preferredCurrency}
          employee={editorTarget}
          onSubmit={this.onEditorSubmit}
          onRequestClose={this.closeEditor}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  employeeList: EmployeeList;
  employeePatch: EmployeePatch;
};
const mapDispatchToProps: MapDispatchToProps = (dispatch: Dispatch) => ({
  employeeList: payload => new Promise((resolve, reject) => {
    dispatch(SalaryPaymentEmployeeActionCreators.list(payload, resolve, reject));
  }),
  employeePatch: payload => new Promise((resolve, reject) => {
    dispatch(SalaryPaymentEmployeeActionCreators.patch(payload, resolve, reject));
  }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeesScreen);
