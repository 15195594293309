import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import memoizeOne from 'memoize-one';
import {
  SalaryPaymentEmployeeActionCreators,
  SalaryPaymentEmployeeActionListPayload,
  SalaryPaymentEmployeeActionListPayloadStatus,
  SalaryPaymentEmployeeActionListResolvePayload,
} from '../redux/salary-payment-employee-actions';
import { Employee } from '../../../api/salary-payment-employee-api';
import { t } from '../../../tools/functions/translation';
import { actionRejectHandler } from '../../../tools/functions/action-promise-handler';
import Modal from '../../shared/components/Modal';

type EmployeeList = (
  payload: SalaryPaymentEmployeeActionListPayload,
) => Promise<SalaryPaymentEmployeeActionListResolvePayload>;

interface Props {
  employeeList: EmployeeList;
  isOpen: boolean;
  onSelect: (employee: Employee) => void;
  onRequestClose: () => void;
  onRequestCreate: () => void;
  exclude?: Array<Employee>;
}

interface State {
  status: SalaryPaymentEmployeeActionListPayloadStatus;
  list: Array<Employee> | null;
  isLoading: boolean;
}

class EmployeeSelectorModal extends React.PureComponent<Props, State> {
  static defaultProps = {
    exclude: [],
  };

  state: State = {
    status: 'all',
    list: null,
    isLoading: true,
  };

  componentDidMount() {
    const { employeeList } = this.props;
    const { status } = this.state;
    employeeList({ status })
      .then(({ data }) => {
        this.setState({
          list: data.results,
          isLoading: false,
        });
      })
      .catch(actionRejectHandler())
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  showActive = (e: React.MouseEvent) => {
    e.preventDefault();
    this.loadList('active');
  };

  showInactive = (e: React.MouseEvent) => {
    e.preventDefault();
    this.loadList('inactive');
  };

  showAll = (e: React.MouseEvent) => {
    e.preventDefault();
    this.loadList('all');
  };

  loadList = (status: SalaryPaymentEmployeeActionListPayloadStatus) => {
    const { employeeList } = this.props;

    this.setState({ status, isLoading: true });
    employeeList({ status })
      .then(({ data }) => {
        this.setState({
          list: data.results,
          isLoading: false,
        });
      })
      .catch(actionRejectHandler())
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  // prettier-ignore
  filterList = memoizeOne(
    (list: Array<Employee>, exclude: Array<Employee>) => list.filter(e => !exclude.find(e2 => e.id === e2.id)),
  );

  onRequestCreate = () => {
    const { onRequestCreate } = this.props;
    onRequestCreate();
  };

  onRequestClose = () => {
    const { onRequestClose } = this.props;
    onRequestClose();
  };

  renderTabs() {
    const { status } = this.state;
    return (
      <div className="tabs">
        <ul>
          <li className={classNames({ 'is-active': status === 'all' })}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={this.showAll}>
              {t('Tous')}
            </a>
          </li>
          <li className={classNames({ 'is-active': status === 'active' })}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={this.showActive}>
              {t('Activés')}
            </a>
          </li>
          <li className={classNames({ 'is-active': status === 'inactive' })}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={this.showInactive}>
              {t('Désactivés')}
            </a>
          </li>
        </ul>
      </div>
    );
  }

  renderList() {
    const { list, isLoading } = this.state;
    if (!list) {
      return null;
    }

    const { onSelect, exclude } = this.props;

    const filteredList = this.filterList(list, exclude!);
    const content = filteredList.map((item) => {
      const { id, lastName, firstName } = item;
      return (
        <tr key={id}>
          <td>{firstName}</td>
          <td>{lastName}</td>
          <td>
            <button type="button" className="button is-small" onClick={() => onSelect(item)}>
              {t('Sélectionner')}
            </button>
          </td>
        </tr>
      );
    });
    return (
      <div className={classNames('table-container', { 'is-loading': isLoading })}>
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>{t('Prénom')}</th>
              <th>{t('Nom')}</th>
              <th>{t('Action')}</th>
            </tr>
          </thead>
          <tbody>{content}</tbody>
        </table>
      </div>
    );
  }

  render() {
    const { isOpen } = this.props;
    const { list } = this.state;

    return (
      <Modal isOpen={isOpen} onRequestClose={this.onRequestClose}>
        <div className="modal-content">
          <button
            type="button"
            className="modal-close is-pulled-right"
            aria-label="close"
            onClick={this.onRequestClose}
          />
          <div className={classNames('box', { 'is-loading': list === null })}>
            {this.renderTabs()}
            {this.renderList()}

            <button type="button" className="button is-primary" onClick={this.onRequestCreate}>
              <span className="icon">
                <FontAwesomeIcon icon={faPlus} />
              </span>
              <span>{t('Ajouter un employé')}</span>
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  employeeList: EmployeeList;
};
const mapDispatchToProps: MapDispatchToProps = (dispatch: Dispatch) => ({
  employeeList: payload => new Promise((resolve, reject) => {
    dispatch(SalaryPaymentEmployeeActionCreators.list(payload, resolve, reject));
  }),
});

export default connect(
  null,
  mapDispatchToProps,
)(EmployeeSelectorModal);
