import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import uuidv4 from 'uuidv4';
import { ApiRequest } from 'koosmik-common';
import history from './config/history';
import initStore from './redux/init-store';
import RootComponent from './modules/base/components/RootComponent';
import initLocale from './config/locale-config';
import initApiRequest from './config/api-request';
import { DeviceActionCreators } from './redux/device/device-actions';

const { store, persistor } = initStore();

const onBeforeLift = () => {
  const state = store.getState();
  if (state.auth) {
    ApiRequest.setAuthorizationBearer(state.auth.jwtToken);
  }

  // When i18n will be enable, load lang from store
  initLocale('fr');

  let deviceId = state.device.id;
  if (!deviceId) {
    deviceId = `webpro-${uuidv4()}`;
    store.dispatch(DeviceActionCreators.set({ id: deviceId }));
  }
  initApiRequest(deviceId);
};

const App: React.FC = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor} onBeforeLift={onBeforeLift}>
      <ConnectedRouter history={history}>
        <RootComponent />
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);

export default App;
