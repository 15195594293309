import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LogoutActionCreators } from '../../../auth/redux/logout-actions';
import UserState from '../../../../redux/user/user-state';
import { t } from '../../../../tools/functions/translation';
import Avatar from '../../../shared/components/Avatar';

type Logout = () => void;

interface Props {
  user: UserState;
  logout: Logout;
}

interface State {
  isOpen: boolean;
}

class NavBarUserMenu extends React.PureComponent<Props, State> {
  renderUser = () => {
    const { user } = this.props;

    if (!user) {
      return <FontAwesomeIcon icon={faSpinner} spin />;
    }

    return (
      <>
        <figure className="image is-32x32">
          <Avatar image={user.avatar} size={32} initials={user.initial} alt="avatar" />
        </figure>
        <span className="user-name">{user.displayName}</span>
      </>
    );
  };

  render() {
    const { logout } = this.props;
    return (
      <div className="navbar-item has-dropdown is-hoverable navbar-user-menu">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="navbar-link" aria-haspopup="listbox" aria-label="user">
          {this.renderUser()}
        </a>
        <div className="navbar-dropdown is-right" role="listbox" aria-label="user">
          <div className="navbar-item">
            <button type="button" className="button is-danger is-fullwidth" onClick={logout}>
              {t('Se déconnecter')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  logout: Logout;
};
const mapDispatchToProps: MapDispatchToProps = dispatch => ({
  logout: () => dispatch(LogoutActionCreators.requested()),
});

export default connect(
  null,
  mapDispatchToProps,
)(NavBarUserMenu);
