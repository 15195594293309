// Types

export const LOGOUT_ACTION_REQUESTED = 'LOGOUT_ACTION_REQUESTED';

// Action creators definitions
export interface LogoutActionRequested {
  type: typeof LOGOUT_ACTION_REQUESTED;
}

export type LogoutActions = LogoutActionRequested;

// Action creators

export const LogoutActionCreators = {
  requested: (): LogoutActionRequested => ({
    type: LOGOUT_ACTION_REQUESTED,
  }),
};
