import { ActionReject, ActionResolve } from '../../../redux/promises';
import { ApiMoneyOperationListResponseData } from '../../../api/money-operation-api';

// Types

export const MONEY_OPERATION_ACTION_LIST = 'MONEY_OPERATION_ACTION_LIST';

// Actions creators definitions

export interface MoneyOperationActionListPayload {
  maxId: string;
}

export interface MoneyOperationActionListResolvePayload {
  data: ApiMoneyOperationListResponseData;
}

export interface MoneyOperationActionList {
  type: typeof MONEY_OPERATION_ACTION_LIST;
  payload: MoneyOperationActionListPayload;
  resolve: ActionResolve<MoneyOperationActionListResolvePayload>;
  reject: ActionReject;
}

export type MoneyOperationActions = MoneyOperationActionList;

// Action creators

export const MoneyOperationActionCreators = {
  moneyOperationList: (
    payload: MoneyOperationActionListPayload,
    resolve: ActionResolve<MoneyOperationActionListResolvePayload>,
    reject: ActionReject,
  ): MoneyOperationActions => ({
    type: MONEY_OPERATION_ACTION_LIST,
    payload,
    resolve,
    reject,
  }),
};
