import AuthState from './auth-state';
import { AUTH_ACTION_CLEAR, AUTH_ACTION_SET, AuthActions } from './auth-actions';

const initialState: AuthState = null;

export default function authReducer(state: AuthState = initialState, action: AuthActions) {
  switch (action.type) {
    case AUTH_ACTION_SET:
      return action.payload;
    case AUTH_ACTION_CLEAR:
      return null;
    default:
      return state;
  }
}
