import React from 'react';
import { CountryPhonePrefixes } from 'koosmik-common/utils';
import { TranslationCountry } from 'koosmik-common/translations';
import Dropdown, { DropdownItem, DropdownItemValue } from './Dropdown';
import { getLocale } from '../../../config/locale-config';
import { t } from '../../../tools/functions/translation';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

class PhoneCountrySelect extends React.PureComponent<Props> {
  topValues = ['TG', 'FR', 'LU'];

  choices: Array<DropdownItem>;

  constructor(props: Props) {
    super(props);

    this.choices = [];

    this.topValues.forEach((countryCode) => {
      this.choices.push({
        type: 'value',
        value: countryCode,
        label: this.renderLabel(countryCode, CountryPhonePrefixes.getPrefix(countryCode)),
        labelSelected: this.renderLabelSelected(CountryPhonePrefixes.getPrefix(countryCode)),
      });
    });

    this.choices.push({ type: 'divider', key: 'd1' });

    const otherChoices: Array<DropdownItemValue> = [];
    Object.entries(CountryPhonePrefixes.prefixes).forEach(([code, prefix]: [string, string]) => {
      if (!this.topValues.includes(code)) {
        otherChoices.push({
          type: 'value',
          value: code,
          label: this.renderLabel(code, prefix),
          labelSelected: this.renderLabelSelected(prefix),
        });
      }
    });
    otherChoices.sort((d1, d2) => d1.label.localeCompare(d2.label, getLocale()));

    this.choices.push(...otherChoices);
  }

  onChange = (data: DropdownItemValue) => {
    const { onChange } = this.props;
    onChange(data.value);
  };

  renderLabel = (code: string, prefix: string) => `${TranslationCountry.translate(code)} +${prefix}`;

  renderLabelSelected = (prefix: string) => `+${prefix}`;

  render() {
    const { value } = this.props;

    return <Dropdown options={this.choices} onChange={this.onChange} value={value} ariaLabel={t('Country')} />;
  }
}

export default PhoneCountrySelect;
