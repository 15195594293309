import CashFeesGridState from './cash-fees-grid-state';
import { CASH_FEES_GRID_ACTION_SAVE, CashFeesGridActions } from './cash-fees-grid-actions';

const initialState: CashFeesGridState = null;

export default function cashFeesGridReducer(state: CashFeesGridState = initialState, action: CashFeesGridActions) {
  switch (action.type) {
    case CASH_FEES_GRID_ACTION_SAVE:
      return action.payload;
    default:
      return state;
  }
}
