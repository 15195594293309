import { call, put, select } from 'redux-saga/effects';
import { ServerErrorCodes } from 'koosmik-common/utils';
import { t } from './translation';
import { AuthActionCreators } from '../../redux/auth/auth-actions';
import { SessionsActionCreators } from '../../redux/sessions/sessions-actions';
import { authSelect } from '../../redux/auth/auth-selectors';
import AuthState from '../../redux/auth/auth-state';

export function* apiCallWrapper<T extends(...args: any[]) => any>(fn: T, ...args: Parameters<T>) {
  try {
    // do the api call
    const response = yield call(fn, ...args);
    const { errorCode } = response;

    const tokenErrorCodes = [
      ServerErrorCodes.TOKEN_EXPIRED,
      ServerErrorCodes.TOKEN_INVALID,
      ServerErrorCodes.TOKEN_MISSING,
      ServerErrorCodes.SESSION_REVOKED,
    ];
    if (tokenErrorCodes.includes(errorCode)) {
      const authState: AuthState = yield select(authSelect);

      if (ServerErrorCodes.SESSION_REVOKED === errorCode && authState !== null) {
        yield put(SessionsActionCreators.revoke(authState.sessionId));
      }

      yield put(AuthActionCreators.clear());

      throw new Error(t('Votre session a expiré. Veillez-vous reconnecter'));
    }
    return response;
  } catch (error) {
    throw error;
  }
}
