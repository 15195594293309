import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import classNames from 'classnames';
import Typed from 'react-typed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import logo from '../../../resources/logo.png';
import { LoginActionCreators, LoginActionSubmitPayload, LoginActionSubmitResolvePayload } from '../redux/login-actions';
import { handleFormSubmitEvent, handleInputChangeEvent } from '../../../tools/functions/html-events-handler';
import RootState from '../../../redux/RootState';
import { sessionsSelectLast } from '../../../redux/sessions/sessions-selectors';
import { SessionState } from '../../../redux/sessions/sessions-state';
import { actionRejectHandler } from '../../../tools/functions/action-promise-handler';
import { t } from '../../../tools/functions/translation';
import { SecondFactorAuthData } from './SecondFactorAuthModal';
import PhoneCountrySelect from '../../shared/components/PhoneCountrySelect';
import InputErrorsText from '../../shared/components/form/InputErrorsText';
import { ApiResponseDataInputErrors } from '../../../api/shared-api-lib';
import { extractInputErrors } from '../../shared/tools/input-errors-helper';

type LoginSubmit = (payload: LoginActionSubmitPayload) => Promise<LoginActionSubmitResolvePayload>;

interface Props {
  loginSubmit: LoginSubmit;
  lastSession: SessionState | null;
  onRequestSecondFactorAuth: (data: SecondFactorAuthData) => void;
  redirectUrl?: string;
}

interface State {
  // login inputs
  type: 'email' | 'phone';
  phoneCountry: string;
  phoneNumber: string;
  email: string;
  password: string;

  // error
  inputErrors: ApiResponseDataInputErrors | null;

  // processing flag
  isProcessing: boolean;
}

class LoginForm extends React.PureComponent<Props, State> {
  state: State = {
    type: 'phone',
    phoneCountry: 'TG',
    phoneNumber: '',
    email: '',
    password: '',

    inputErrors: null,

    isProcessing: false,
  };

  constructor(props: Props) {
    super(props);

    const { lastSession } = this.props;
    if (lastSession) {
      if (lastSession.type === 'email') {
        this.state.type = 'email';
        this.state.email = lastSession.email;
      } else {
        this.state.type = 'phone';
        this.state.phoneCountry = lastSession.phone.country;
        this.state.phoneNumber = lastSession.phone.number;
      }
    }
  }

  handleEmail = handleInputChangeEvent((email: string) => this.setState({ email }));

  handlePhoneCountry = (phoneCountry: string) => this.setState({ phoneCountry });

  handlePhoneNumber = handleInputChangeEvent((phoneNumber: string) => this.setState({ phoneNumber }));

  handlePassword = handleInputChangeEvent((password: string) => this.setState({ password }));

  toggleType = handleFormSubmitEvent(() => {
    const { type } = this.state;
    if (type === 'email') {
      this.setState({ type: 'phone' });
    } else {
      this.setState({ type: 'email' });
    }
  });

  loginSubmit = handleFormSubmitEvent(() => {
    const { loginSubmit, onRequestSecondFactorAuth, redirectUrl } = this.props;

    const {
      type, email, phoneCountry, phoneNumber, password,
    } = this.state;

    let payload: LoginActionSubmitPayload | null = null;
    if (type === 'email') {
      payload = {
        type,
        email,
        password,
        redirectUrl,
      };
    } else {
      const phone = {
        country: phoneCountry,
        number: phoneNumber,
      };
      payload = {
        type,
        phone,
        password,
        redirectUrl,
      };
    }

    this.setState({ isProcessing: true });
    loginSubmit(payload)
      .then(({ data }) => {
        if (data.secondFactorAuthId) {
          onRequestSecondFactorAuth({
            secondFactorAuthId: data.secondFactorAuthId,
            loginActionSubmitPayload: payload!,
          });
          this.setState({ isProcessing: false, password: '' });
        } // not setState outside because the component will probably be unmounted
      })
      .catch(
        actionRejectHandler({
          onInputInvalid: inputErrors => this.setState({ inputErrors }),
          always: () => this.setState({ isProcessing: false, password: '' }),
        }),
      );
  });

  renderEmailInput() {
    const { email, inputErrors } = this.state;

    const emailErrors = extractInputErrors('email', inputErrors);

    return (
      <div className="field">
        <label>
          <p className="control has-icons-left">
            <input
              type="email"
              className={classNames('input', { 'is-danger': emailErrors })}
              value={email}
              onChange={this.handleEmail}
              placeholder={t('Email')}
              aria-label={t('Email')}
              required
            />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
          </p>
          <InputErrorsText inputErrors={emailErrors} />
        </label>
      </div>
    );
  }

  renderPhoneInputs() {
    const { phoneCountry, phoneNumber, inputErrors } = this.state;

    const phoneNumberErrors = extractInputErrors('phoneNumber', inputErrors);

    return (
      <div className="field">
        <div className="control">
          <div className="field has-addons">
            <div className="control">
              <PhoneCountrySelect value={phoneCountry} onChange={this.handlePhoneCountry} />
            </div>
            <p className="control is-expanded">
              <input
                type="text"
                className={classNames('input', { 'is-danger': phoneNumberErrors })}
                value={phoneNumber}
                onChange={this.handlePhoneNumber}
                placeholder={t('Phone number')}
                aria-label={t('Phone number')}
                required
              />
              <InputErrorsText inputErrors={phoneNumberErrors} />
            </p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      type, password, inputErrors, isProcessing,
    } = this.state;

    const passwordErrors = extractInputErrors('password', inputErrors);

    return (
      <div className="card">
        <header className="card-header">
          <h1 className="card-header-title is-centered">
            <Typed className="primary-title" strings={['Koosmik Pro']} typeSpeed={80} />
          </h1>
        </header>
        <div className="card-content">
          <div className="columns is-mobile is-vflex-end">
            <div className="column">
              <figure className="image is-128x128">
                <img src={logo} alt="Koosmik" />
              </figure>
            </div>
            <div className="column is-narrow">
              <button type="button" className="button" onClick={this.toggleType}>
                {type === 'email' ? t('Par téléphone') : t('Par email')}
              </button>
            </div>
          </div>
          <form onSubmit={this.loginSubmit}>
            {type === 'email' ? this.renderEmailInput() : this.renderPhoneInputs()}
            <div className="field">
              <p className="control has-icons-left">
                <input
                  className={classNames('input', { 'is-danger': passwordErrors })}
                  type="password"
                  value={password}
                  onChange={this.handlePassword}
                  placeholder={t('Password')}
                  aria-label={t('Password')}
                  required
                />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faLock} />
                </span>
              </p>
              <InputErrorsText inputErrors={passwordErrors} />
            </div>
            <div className="has-text-centered">
              <button type="submit" className={classNames('button is-primary', { 'is-loading': isProcessing })}>
                {t('Se connecter')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  lastSession: sessionsSelectLast(state),
});

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  loginSubmit: LoginSubmit;
};
const mapDispatchToProps: MapDispatchToProps = dispatch => ({
  loginSubmit: payload => new Promise((resolve, reject) => {
    dispatch(LoginActionCreators.submit(payload, resolve, reject));
  }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginForm);
