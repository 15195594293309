import { ApiRequest } from 'koosmik-common';
import { ApiResponseDataInputErrors, ApiDefaultResponse } from './shared-api-lib';

// Login
interface ApiLoginEmailPayload {
  email: string;
  password: string;
  session?: string | null;
}

interface ApiLoginPhonePayload {
  phoneCountry: string;
  phoneNumber: string;
  password: string;
  session?: string | null;
}

export interface ApiLoginResponseData {
  session: string;
  token: string;
  inputErrors?: ApiResponseDataInputErrors;
  secondFactorAuthId?: string;
  area: 'AFRI' | 'EURO';
  kycType: 'legal' | 'natural';
}

export interface ApiLoginResponse extends ApiDefaultResponse<ApiLoginResponseData> {}

type ApiLoginEmail = (payload: ApiLoginEmailPayload) => Promise<ApiLoginResponse>;
export const apiLoginEmail = ApiRequest.auth.login.login as ApiLoginEmail;

type ApiLoginPhone = (payload: ApiLoginPhonePayload) => Promise<ApiLoginResponse>;
export const apiLoginPhone = ApiRequest.auth.login.phoneLogin as ApiLoginPhone;

// Login double auth

interface ApiLoginSecondFactorAuthPayload {
  code: string;
  secondFactorAuthId: string;
}

export interface ApiLoginSecondFactorAuthResponseData {
  session: string;
  token: string;
  inputErrors?: ApiResponseDataInputErrors;
}

export interface ApiLoginSecondFactorAuthResponse extends ApiDefaultResponse<ApiLoginSecondFactorAuthResponseData> {}

type ApiSecondFactorAuth = (payload: ApiLoginSecondFactorAuthPayload) => Promise<ApiLoginSecondFactorAuthResponse>;
export const apiSecondFactorAuth = ApiRequest.auth.login.loginSecondFactorAuth as ApiSecondFactorAuth;
