import { call, takeEvery } from 'redux-saga/effects';
import {
  apiSalaryPaymentEmployeeList,
  apiSalaryPaymentEmployeeAdd,
  ApiSalaryPaymentEmployeeAddResponse,
  ApiSalaryPaymentEmployeeListResponse,
  apiSalaryPaymentEmployeeEdit,
  ApiSalaryPaymentEmployeeListPayload,
  apiSalaryPaymentEmployeePatch,
  ApiSalaryPaymentEmployeePatchResponse,
  ApiSalaryPaymentEmployeeEditResponse,
} from '../../../api/salary-payment-employee-api';
import {
  SalaryPaymentEmployeeActionList,
  SalaryPaymentEmployeeActionAdd,
  SALARY_PAYMENT_EMPLOYEE_ACTION_LIST,
  SALARY_PAYMENT_EMPLOYEE_ACTION_ADD,
  SALARY_PAYMENT_EMPLOYEE_ACTION_EDIT,
  SalaryPaymentEmployeeActionEdit,
  SALARY_PAYMENT_EMPLOYEE_ACTION_PATCH,
  SalaryPaymentEmployeeActionPatch,
} from './salary-payment-employee-actions';
import { apiCallWrapper } from '../../../tools/functions/api-call-handler';

function* salaryPaymentEmployeeList({ payload, resolve, reject }: SalaryPaymentEmployeeActionList) {
  try {
    const args: ApiSalaryPaymentEmployeeListPayload = {};
    if (payload.status === 'active') {
      args.disabled = false;
    } else if (payload.status === 'inactive') {
      args.disabled = true;
    }
    const response: ApiSalaryPaymentEmployeeListResponse = yield call(
      apiCallWrapper,
      apiSalaryPaymentEmployeeList,
      args,
    );

    const { success, data, errorCode } = response;
    if (success) {
      resolve({ data });
      return;
    }

    reject({ errorCode });
  } catch (error) {
    reject({ error });
  }
}

function* salaryPaymentEmployeeAdd({ payload, resolve, reject }: SalaryPaymentEmployeeActionAdd) {
  try {
    const response: ApiSalaryPaymentEmployeeAddResponse = yield call(
      apiCallWrapper,
      apiSalaryPaymentEmployeeAdd,
      payload,
    );

    const { success, data, errorCode } = response;
    if (success) {
      resolve({ data });
      return;
    }

    reject({
      errorCode,
      inputErrors: data.inputErrors,
    });
  } catch (error) {
    reject({ error });
  }
}

function* salaryPaymentEmployeeEdit({ payload, resolve, reject }: SalaryPaymentEmployeeActionEdit) {
  try {
    const response: ApiSalaryPaymentEmployeeEditResponse = yield call(
      apiCallWrapper,
      apiSalaryPaymentEmployeeEdit,
      payload,
    );

    const { success, data, errorCode } = response;
    if (success) {
      resolve({ data });
      return;
    }

    reject({
      errorCode,
      inputErrors: data.inputErrors,
    });
  } catch (error) {
    reject({ error });
  }
}

function* salaryPaymentEmployeePatch({ payload, resolve, reject }: SalaryPaymentEmployeeActionPatch) {
  try {
    const response: ApiSalaryPaymentEmployeePatchResponse = yield call(
      apiCallWrapper,
      apiSalaryPaymentEmployeePatch,
      payload,
    );

    const { success, data, errorCode } = response;
    if (success) {
      resolve({ data });
      return;
    }

    reject({
      errorCode,
      inputErrors: data.inputErrors,
    });
  } catch (error) {
    reject({ error });
  }
}

export default function* salaryPaymentEmployeeSaga() {
  yield takeEvery(SALARY_PAYMENT_EMPLOYEE_ACTION_LIST, salaryPaymentEmployeeList);
  yield takeEvery(SALARY_PAYMENT_EMPLOYEE_ACTION_ADD, salaryPaymentEmployeeAdd);
  yield takeEvery(SALARY_PAYMENT_EMPLOYEE_ACTION_EDIT, salaryPaymentEmployeeEdit);
  yield takeEvery(SALARY_PAYMENT_EMPLOYEE_ACTION_PATCH, salaryPaymentEmployeePatch);
}
