import { call, takeEvery } from 'redux-saga/effects';
import {
  apiSalaryPaymentBulkList,
  ApiSalaryPaymentBulkListResponse,
  ApiSalaryPaymentBulkGetResponse,
  apiSalaryPaymentBulkGet,
  apiSalaryPaymentBulkCreate,
  ApiSalaryPaymentBulkCreateResponse,
} from '../../../api/salary-payment-bulk-api';
import {
  SalaryPaymentBulkActionList,
  SALARY_PAYMENT_BULK_ACTION_LIST,
  SALARY_PAYMENT_BULK_ACTION_GET,
  SalaryPaymentBulkActionGet,
  SALARY_PAYMENT_BULK_ACTION_CREATE,
  SalaryPaymentBulkActionCreate,
} from './salary-payment-bulk-actions';
import { apiCallWrapper } from '../../../tools/functions/api-call-handler';

function* salaryPaymentBulkList({ payload, resolve, reject }: SalaryPaymentBulkActionList) {
  try {
    const response: ApiSalaryPaymentBulkListResponse = yield call(apiCallWrapper, apiSalaryPaymentBulkList, payload);

    const { success, data, errorCode } = response;
    if (success) {
      resolve({ data });
      return;
    }

    reject({ errorCode });
  } catch (error) {
    reject({ error });
  }
}

function* salaryPaymentBulkGet({ payload, resolve, reject }: SalaryPaymentBulkActionGet) {
  try {
    const response: ApiSalaryPaymentBulkGetResponse = yield call(apiCallWrapper, apiSalaryPaymentBulkGet, payload);

    const { success, data, errorCode } = response;
    if (success) {
      resolve({ data });
      return;
    }

    reject({ errorCode });
  } catch (error) {
    reject({ error });
  }
}

function* salaryPaymentBulkCreate({ payload, resolve, reject }: SalaryPaymentBulkActionCreate) {
  try {
    const response: ApiSalaryPaymentBulkCreateResponse = yield call(
      apiCallWrapper,
      apiSalaryPaymentBulkCreate,
      payload,
    );

    const { success, data, errorCode } = response;
    if (success) {
      resolve({ data });
      return;
    }

    reject({
      errorCode,
      inputErrors: data.inputErrors,
    });
  } catch (error) {
    reject({ error });
  }
}

export default function* salaryPaymentBulkSaga() {
  yield takeEvery(SALARY_PAYMENT_BULK_ACTION_LIST, salaryPaymentBulkList);
  yield takeEvery(SALARY_PAYMENT_BULK_ACTION_GET, salaryPaymentBulkGet);
  yield takeEvery(SALARY_PAYMENT_BULK_ACTION_CREATE, salaryPaymentBulkCreate);
}
