import React from 'react';
import moment from 'moment-timezone';
import { DateTimePicker } from 'react-widgets';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { handleInputChangeEvent, handleSelectChangeEvent } from '../../../tools/functions/html-events-handler';
import { t } from '../../../tools/functions/translation';
import RootState from '../../../redux/RootState';
import UserState from '../../../redux/user/user-state';
import { ApiResponseDataInputErrors } from '../../../api/shared-api-lib';
import { extractInputErrors } from '../../shared/tools/input-errors-helper';
import InputErrorsText from '../../shared/components/form/InputErrorsText';

export interface BulkEditorCoreValues {
  communication: string;
  scheduledAt: string | null;
  timeZone: string;
}

interface Props {
  user: UserState;
  onChange: (values: BulkEditorCoreValues) => void;
  inputErrors: ApiResponseDataInputErrors | null;
}

interface State extends BulkEditorCoreValues {
  scheduledAtDate?: Date;
}

class BulkCoreEditor extends React.PureComponent<Props, State> {
  state: State = {
    communication: '',
    scheduledAt: null,
    timeZone: '',
    scheduledAtDate: undefined,
  };

  private timeZones: string[];

  constructor(props: Props) {
    super(props);

    const { user } = props;

    const date = new Date();
    date.setHours(date.getHours() + 1, 0, 0, 0);

    this.timeZones = moment.tz.names();

    this.state = {
      ...this.state,
      communication: `${user!.displayName} - ${t('Salaire')}`,
      scheduledAt: this.dateToString(date),
      timeZone: moment.tz.guess(),
      scheduledAtDate: date,
    };
    this.triggerOnChange();
  }

  handleCommunication = handleInputChangeEvent((communication: string) => this.update({ communication }));

  handleScheduledAt = (date?: Date) => this.update({
    scheduledAtDate: date,
    scheduledAt: this.dateToString(date),
  });

  handleTimeZone = handleSelectChangeEvent((timeZone: string) => this.update({ timeZone }));

  dateToString = (date?: Date) => (date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null);

  triggerOnChange = () => {
    const { communication, timeZone, scheduledAt } = this.state;
    const { onChange } = this.props;
    onChange({
      communication,
      timeZone,
      scheduledAt,
    });
  };

  /* eslint-disable-next-line arrow-parens */
  update = <K extends keyof State>(newState: Pick<State, K>) => {
    this.setState(newState, this.triggerOnChange);
  };

  renderTimeZone = (timeZone: string) => (
    <option value={timeZone} key={timeZone}>
      {timeZone}
    </option>
  );

  render() {
    const { communication, scheduledAtDate, timeZone } = this.state;

    const { inputErrors } = this.props;
    const communicationErrors = extractInputErrors('communication', inputErrors);
    const scheduledAtErrors = extractInputErrors('scheduledAt', inputErrors);
    const timeZoneErrors = extractInputErrors('timeZone', inputErrors);

    return (
      <div>
        <div className="field">
          <label className="label" htmlFor="communication">
            {t('Communication')}
          </label>
          <div className="control">
            <input
              type="text"
              className={classNames('input', { 'is-danger': communicationErrors })}
              value={communication}
              id="communication"
              onChange={this.handleCommunication}
              required
            />
            <InputErrorsText inputErrors={communicationErrors} />
          </div>
        </div>

        <div className="field">
          <label className="label" htmlFor="scheduledAt_input">
            {t('Programmer pour le')}
          </label>
          <div className="control">
            <DateTimePicker value={scheduledAtDate} id="scheduledAt" onChange={this.handleScheduledAt} />
            <InputErrorsText inputErrors={scheduledAtErrors} />
          </div>
        </div>

        <div className="field">
          <label className="label" htmlFor="timeZone">
            {t('Fuseau horaire')}
          </label>
          <div className="control">
            <div className={classNames('select is-fullwidth', { 'is-danger': timeZoneErrors })}>
              <select value={timeZone} onChange={this.handleTimeZone} id="timeZone" required>
                {this.timeZones.map(this.renderTimeZone)}
              </select>
            </div>
            <InputErrorsText inputErrors={timeZoneErrors} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

export default connect(mapStateToProps)(BulkCoreEditor);
