import { put, takeEvery } from 'redux-saga/effects';
import { routerActions } from 'connected-react-router';
import { UserActionCreators } from '../../../redux/user/user-actions';
import { LOGOUT_ACTION_REQUESTED } from './logout-actions';
import { RoutesUrls } from '../../../config/Routes';
import { AuthActionCreators } from '../../../redux/auth/auth-actions';

function* logoutRequested() {
  yield put(AuthActionCreators.clear());
  yield put(UserActionCreators.clear());
  yield put(routerActions.push(RoutesUrls.login));
}

export default function* logoutSaga() {
  yield takeEvery(LOGOUT_ACTION_REQUESTED, logoutRequested);
}
