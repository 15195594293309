import { fork } from 'redux-saga/effects';
import loginSaga from '../modules/auth/redux/login-saga';
import logoutSaga from '../modules/auth/redux/logout-saga';
import userSaga from './user/user-saga';
import authSaga from './auth/auth-saga';
import moneyOperationSaga from '../modules/dashboard/redux/money-operation-saga';
import salaryPaymentEmployeeSaga from '../modules/salary-payment/redux/salary-payment-employee-saga';
import salaryPaymentBulkSaga from '../modules/salary-payment/redux/salary-payment-bulk-saga';
import cashFeesGridSaga from '../modules/shared/redux/cash-fees-grid/cash-fees-grid-saga';

export default function* rootSagas() {
  yield fork(userSaga);
  yield fork(authSaga);
  yield fork(loginSaga);
  yield fork(logoutSaga);
  yield fork(moneyOperationSaga);
  yield fork(salaryPaymentEmployeeSaga);
  yield fork(salaryPaymentBulkSaga);
  yield fork(cashFeesGridSaga);
}
