import moment from 'moment-timezone';
import { ApiDate } from '../../api/shared-api-lib';

export const parseDate = (date: ApiDate) => moment.unix(date.timestamp).tz(date.timezone);

export const formatDate = (date: ApiDate, format: string) => parseDate(date).format(format);

type DateTimeSize = 'short' | 'long' | 'none';

export const formatDateLocale = (date: ApiDate, dateType: DateTimeSize, timeSize: DateTimeSize) => {
  let dateFormat = '';
  switch (dateType) {
    case 'long':
      dateFormat = 'LL';
      break;
    case 'short':
      dateFormat = 'L';
      break;
  }

  let timeFormat = '';
  switch (timeSize) {
    case 'long':
      timeFormat = 'LTS';
      break;
    case 'short':
      timeFormat = 'LT';
      break;
  }

  return formatDate(date, `${dateFormat} ${timeFormat}`.trim());
};

// prettier-ignore
export const datesIsSameDay = (date1: ApiDate, date2: ApiDate) => (
  date1.date.substring(0, 10) === date2.date.substring(0, 10)
);
