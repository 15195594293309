import { ApiRequest } from 'koosmik-common';
import { ApiDefaultResponse, ApiResponseDataInputErrors } from './shared-api-lib';

const api = ApiRequest.api.salaryPayment;
export type Employee = {
  id: string;
  email: string | null;
  phoneCountry: string | null;
  phoneCountryPrefix: string | null;
  phoneNumber: string | null;
  firstName: string;
  lastName: string;
  salary: number;
  hourlyRate: number | null;
  currency: string;
  disabled: boolean;
};

// Salary payment employees employeeListLoad

export interface ApiSalaryPaymentEmployeeListPayload {
  disabled?: boolean | null;
}

export interface ApiSalaryPaymentEmployeeListResponseData {
  results: Array<Employee>;
}

export interface ApiSalaryPaymentEmployeeListResponse
  extends ApiDefaultResponse<ApiSalaryPaymentEmployeeListResponseData> {}

type ApiSalaryPaymentEmployeeList = (
  payload: ApiSalaryPaymentEmployeeListPayload,
) => Promise<ApiSalaryPaymentEmployeeListResponse>;
export const apiSalaryPaymentEmployeeList = api.getEmployeeList as ApiSalaryPaymentEmployeeList;

// Salary payment employee add

export interface ApiSalaryPaymentEmployeeAddPayload {
  email: string | null;
  phoneCountry: string | null;
  phoneNumber: string | null;
  firstName: string;
  lastName: string;
  salary: number;
  hourlyRate: number | null;
  currency: string;
}

export interface ApiSalaryPaymentEmployeeAddResponseData extends Employee {
  inputErrors?: ApiResponseDataInputErrors;
}

export interface ApiSalaryPaymentEmployeeAddResponse
  extends ApiDefaultResponse<ApiSalaryPaymentEmployeeAddResponseData> {}

type ApiSalaryPaymentEmployeeAdd = (
  payload: ApiSalaryPaymentEmployeeAddPayload,
) => Promise<ApiSalaryPaymentEmployeeAddResponse>;
export const apiSalaryPaymentEmployeeAdd = api.addEmployee as ApiSalaryPaymentEmployeeAdd;

// Salary payment employee edit

export interface ApiSalaryPaymentEmployeeEditPayload extends ApiSalaryPaymentEmployeeAddPayload {
  employeeId: string;
}

export interface ApiSalaryPaymentEmployeeEditResponseData extends ApiSalaryPaymentEmployeeAddResponseData {}

export interface ApiSalaryPaymentEmployeeEditResponse
  extends ApiDefaultResponse<ApiSalaryPaymentEmployeeEditResponseData> {}

type ApiSalaryPaymentEmployeeEdit = (
  payload: ApiSalaryPaymentEmployeeEditPayload,
) => Promise<ApiSalaryPaymentEmployeeEditResponse>;
export const apiSalaryPaymentEmployeeEdit = api.updateEmployee as ApiSalaryPaymentEmployeeEdit;

// Salary payment employee patch

export interface ApiSalaryPaymentEmployeePatchPayload {
  employeeId: string;
  disabled?: boolean;
}

export interface ApiSalaryPaymentEmployeePatchResponseData extends ApiSalaryPaymentEmployeeAddResponseData {}

export interface ApiSalaryPaymentEmployeePatchResponse
  extends ApiDefaultResponse<ApiSalaryPaymentEmployeePatchResponseData> {}

type ApiSalaryPaymentEmployeePatch = (
  payload: ApiSalaryPaymentEmployeePatchPayload,
) => Promise<ApiSalaryPaymentEmployeePatchResponse>;
export const apiSalaryPaymentEmployeePatch = api.patchEmployee as ApiSalaryPaymentEmployeePatch;
