import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { SignInRoutes } from '../../../config/Routes';
import RootState from '../../../redux/RootState';
import UserState from '../../../redux/user/user-state';
import { UserActionCreators } from '../../../redux/user/user-actions';
import NavBarComponent from './layouts/NavBarComponent';

type LoadUser = () => Promise<{}>;

interface Props {
  user: UserState;
  loadUser: LoadUser;
}

class SignInComponent extends React.PureComponent<Props> {
  componentDidMount() {
    const { user, loadUser } = this.props;
    if (!user) {
      loadUser().finally();
    }
  }

  renderContent() {
    const { user } = this.props;

    if (user === null) {
      return <div className="section is-loading" />;
    }

    return <SignInRoutes />;
  }

  render() {
    return (
      <div>
        <NavBarComponent />
        {this.renderContent()}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  loadUser: LoadUser;
};
const mapDispatchToProps: MapDispatchToProps = dispatch => ({
  loadUser: () => new Promise((resolve, reject) => dispatch(UserActionCreators.load(resolve, reject))),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignInComponent);
