import UserState from './user-state';
import { ActionReject, ActionResolve } from '../promises';

// Types

export const USER_ACTION_LOAD = 'USER_ACTION_LOAD';
export const USER_ACTION_SET = 'USER_ACTION_SET';
export const USER_ACTION_CLEAR = 'USER_ACTION_CLEAR';

// Actions creator definitions

export interface UserActionLoad {
  type: typeof USER_ACTION_LOAD;
  resolve: ActionResolve;
  reject: ActionReject;
}

export interface UserActionSet {
  type: typeof USER_ACTION_SET;
  payload: NonNullable<UserState>;
}

export interface UserActionClear {
  type: typeof USER_ACTION_CLEAR;
}

export type UserActions = UserActionLoad | UserActionSet | UserActionClear;

// Action creators

export const UserActionCreators = {
  load: (resolve: ActionResolve, reject: ActionReject): UserActionLoad => ({
    type: USER_ACTION_LOAD,
    resolve,
    reject,
  }),
  set: (payload: NonNullable<UserState>): UserActionSet => ({
    type: USER_ACTION_SET,
    payload,
  }),
  clear: (): UserActionClear => ({
    type: USER_ACTION_CLEAR,
  }),
};
