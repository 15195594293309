import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';
import { routerActions } from 'connected-react-router';
import {
  LOGIN_SECOND_FACTOR_AUTH_ACTION_SUBMIT,
  LoginSecondFactorAuthActionSubmit,
  LOGIN_ACTION_SUBMIT,
  LoginActionSubmit,
} from './login-actions';
import {
  apiLoginEmail,
  apiLoginPhone,
  ApiLoginResponse,
  apiSecondFactorAuth,
  ApiLoginSecondFactorAuthResponse,
} from '../../../api/login-api';
import { AuthActionCreators } from '../../../redux/auth/auth-actions';
import { SessionsActionCreators } from '../../../redux/sessions/sessions-actions';
import { RoutesUrls } from '../../../config/Routes';
import { sessionsSelectOneByEmail, sessionsSelectOneByPhone } from '../../../redux/sessions/sessions-selectors';
import { apiCallWrapper } from '../../../tools/functions/api-call-handler';
import { t } from '../../../tools/functions/translation';

function* loginSubmit({ payload, resolve, reject }: LoginActionSubmit) {
  const { password, redirectUrl } = payload;

  try {
    // request api login
    let response: ApiLoginResponse;
    if (payload.type === 'email') {
      const { email } = payload;
      const session = yield select(sessionsSelectOneByEmail, email);
      response = yield call(apiCallWrapper, apiLoginEmail, {
        email,
        password,
        session: session ? session.sessionId : null,
      });
    } else {
      // phone
      const { phone } = payload;
      const session = yield select(sessionsSelectOneByPhone, phone.country, phone.number);
      response = yield call(apiCallWrapper, apiLoginPhone, {
        phoneCountry: phone.country,
        phoneNumber: phone.number,
        password,
        session: session ? session.sessionId : null,
      });
    }

    // handle response
    const { success, data, errorCode } = response;

    if (!success) {
      reject({
        errorCode,
        inputErrors: data.inputErrors,
      });
      return;
    }

    if (data.area !== 'AFRI') {
      reject({ error: new Error(t("L'application n'est disponible que pour les comptes de la zone FCFA")) });
      return;
    }

    if (data.kycType !== 'legal') {
      reject({ error: new Error(t("L'application n'est disponible que pour les comptes pro")) });
      return;
    }

    if (data.secondFactorAuthId) {
      resolve({ data });
      return;
    }

    yield put(
      AuthActionCreators.set({
        jwtToken: data.token,
        sessionId: data.session,
      }),
    );
    if (payload.type === 'email') {
      const { email } = payload;
      yield put(
        SessionsActionCreators.add({
          email,
          type: 'email',
          sessionId: data.session,
        }),
      );
    } else {
      // phone
      const { phone } = payload;
      yield put(
        SessionsActionCreators.add({
          phone,
          type: 'phone',
          sessionId: data.session,
        }),
      );
    }
    if (redirectUrl) {
      yield put(routerActions.push(redirectUrl));
    } else {
      yield put(routerActions.push(RoutesUrls.dashboard));
    }
    resolve({ data });
  } catch (error) {
    reject({ error });
  }
}

function* loginSecondFactorAuthSubmit({ payload, resolve, reject }: LoginSecondFactorAuthActionSubmit) {
  const { loginActionSubmitPayload, code, secondFactorAuthId } = payload;

  try {
    const response: ApiLoginSecondFactorAuthResponse = yield call(apiCallWrapper, apiSecondFactorAuth, {
      code,
      secondFactorAuthId,
    });

    const { success, data, errorCode } = response;
    if (!success) {
      reject({
        errorCode,
        inputErrors: data.inputErrors,
      });
      return;
    }

    yield put(
      AuthActionCreators.set({
        jwtToken: data.token,
        sessionId: data.session,
      }),
    );
    if (loginActionSubmitPayload.type === 'email') {
      const { email } = loginActionSubmitPayload;
      yield put(
        SessionsActionCreators.add({
          email,
          type: 'email',
          sessionId: data.session,
        }),
      );
    } else {
      // phone
      const { phone } = loginActionSubmitPayload;
      yield put(
        SessionsActionCreators.add({
          phone,
          type: 'phone',
          sessionId: data.session,
        }),
      );
    }

    const { redirectUrl } = loginActionSubmitPayload;
    if (redirectUrl) {
      yield put(routerActions.push(redirectUrl));
    } else {
      yield put(routerActions.push(RoutesUrls.dashboard));
    }
    resolve({ data });
  } catch (error) {
    reject({ error });
  }
}

export default function* loginSaga() {
  yield takeEvery(LOGIN_ACTION_SUBMIT, loginSubmit);
  yield takeEvery(LOGIN_SECOND_FACTOR_AUTH_ACTION_SUBMIT, loginSecondFactorAuthSubmit);
}
