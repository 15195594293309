import React from 'react';
import { generateImageFromLetter } from '../tools/letter-image-generator';

interface Props {
  image: {
    small: string;
    medium: string;
    large: string;
  } | null;
  size: number;
  initials: string;
  alt: string;
}

class Avatar extends React.PureComponent<Props> {
  render() {
    const {
      image, size, alt, initials,
    } = this.props;

    if (image) {
      let src = image.small;
      if (size > 128) {
        src = image.large;
      } else if (size > 64) {
        src = image.medium;
      }

      return <img src={src} className="is-rounded" alt={alt} />;
    }

    return <img src={generateImageFromLetter(initials, size)} className="is-rounded" alt={alt} />;
  }
}

export default Avatar;
