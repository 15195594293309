import React, { InputHTMLAttributes, Fragment } from 'react';
import { TranslationFormInputError } from 'koosmik-common/translations';
import memoizeOne from 'memoize-one';
import { InputErrorsLeaf } from '../../../../api/shared-api-lib';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  inputErrors: InputErrorsLeaf | null;
}

class InputErrorsText extends React.PureComponent<Props> {
  getErrors = memoizeOne((inputErrors: InputErrorsLeaf) => Object.keys(inputErrors));

  render() {
    const { inputErrors } = this.props;
    if (!inputErrors) {
      return null;
    }

    const errors = this.getErrors(inputErrors);

    if (errors.length === 0) {
      return null;
    }

    return (
      <p className="help is-danger">
        {errors.map((e) => {
          const text = TranslationFormInputError.translate(e);

          return (
            <Fragment key={e}>
              <span>{text}</span>
              {e !== errors[0] && <br />}
            </Fragment>
          );
        })}
      </p>
    );
  }
}

export default InputErrorsText;
