import React from 'react';
import ReactModal from 'react-modal';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  children: React.ReactNode;
}

class Modal extends React.PureComponent<Props> {
  modalStyle = {
    overlay: {
      position: 'fixed',
      zIndex: 999,
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      outline: 'none',
    },
  };

  onRequestClose = () => {
    const { onRequestClose } = this.props;
    onRequestClose();
  };

  render() {
    const { isOpen } = this.props;
    if (!isOpen) {
      return null;
    }

    const { children } = this.props;
    return (
      <ReactModal
        htmlOpenClassName="is-clipped"
        className="modal is-active"
        overlayClassName="modal-background"
        isOpen={isOpen}
        onRequestClose={this.onRequestClose}
        contentLabel="Modal"
        style={this.modalStyle}
      >
        {children}
      </ReactModal>
    );
  }
}

export default Modal;
