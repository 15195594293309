const Env = {
  apiUrl: () => process.env.REACT_APP_API_URL,

  // only on small secondFactorCode, prefer direct access to process.env
  // because the secondFactorCode portion will be removed en build
  isDev: () => process.env.NODE_ENV === 'development',
  version: () => '1.0.0',
};

export default Env;
