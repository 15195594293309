import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LoginScreen from '../modules/auth/screens/LoginScreen';
import DashboardScreen from '../modules/dashboard/screens/DashboardScreen';
import SignIn404Screen from '../modules/base/components/screens/SignIn404Screen';
import SignOut404Screen from '../modules/base/components/screens/SignOut404Screen';
import EmployeesScreen from '../modules/salary-payment/screens/EmployeesScreen';
import BulksScreen from '../modules/salary-payment/screens/BulksScreen';
import BulkViewScreen from '../modules/salary-payment/screens/BulkViewScreen';
import BulkCreateScreen from '../modules/salary-payment/screens/BulkCreateScreen';

export const RoutesUrls = {
  // sign out
  login: '/',

  // sign in
  dashboard: '/',
  salaryPaymentEmployees: '/employees',
  salaryPaymentBulks: '/salary-payment/',
  salaryPaymentBulkView: '/salary-payment/view/:bulkId',
  salaryPaymentBulkViewGen: (id: string) => `/salary-payment/view/${id}`,
  salaryPaymentBulkCreate: '/salary-payment/create',
};

export const SignInRoutes = () => (
  <Switch>
    <Route exact path={RoutesUrls.dashboard} component={DashboardScreen} />
    <Route exact path={RoutesUrls.salaryPaymentEmployees} component={EmployeesScreen} />
    <Route exact path={RoutesUrls.salaryPaymentBulks} component={BulksScreen} />
    <Route path={RoutesUrls.salaryPaymentBulkView} component={BulkViewScreen} />
    <Route path={RoutesUrls.salaryPaymentBulkCreate} component={BulkCreateScreen} />
    <Route component={SignIn404Screen} />
  </Switch>
);

export const SignOutRoutes = () => (
  <Switch>
    <Route exact path={RoutesUrls.login} component={LoginScreen} />
    <Route component={SignOut404Screen} />
  </Switch>
);
