import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import classNames from 'classnames';
import {
  SalaryPaymentBulkActionCreators,
  SalaryPaymentBulkActionGetPayload,
  SalaryPaymentBulkActionGetResolvePayload,
} from '../redux/salary-payment-bulk-actions';
import { t } from '../../../tools/functions/translation';
import { actionRejectHandler } from '../../../tools/functions/action-promise-handler';
import { ApiSalaryPaymentBulkGetResponseData, SalaryPaymentBulkItem } from '../../../api/salary-payment-bulk-api';
import { formatDate } from '../../../tools/functions/date';
import { money } from '../../../tools/functions/money';
import { formatPhoneNumber } from '../../../tools/functions/formatters';

type BulkGet = (payload: SalaryPaymentBulkActionGetPayload) => Promise<SalaryPaymentBulkActionGetResolvePayload>;

interface RouteMatchParams {
  bulkId: string;
}

interface Props extends RouteComponentProps<RouteMatchParams> {
  bulkGet: BulkGet;
}

interface State {
  data: ApiSalaryPaymentBulkGetResponseData | null;
}

class BulkViewScreen extends React.PureComponent<Props, State> {
  state: State = {
    data: null,
  };

  componentDidMount() {
    const { match, bulkGet } = this.props;
    bulkGet({ bulkId: match.params.bulkId })
      .then(({ data }) => {
        this.setState({
          data,
        });
      })
      .catch(actionRejectHandler());
  }

  renderItems(items: Array<SalaryPaymentBulkItem>) {
    const { data } = this.state;
    if (!data) {
      return null;
    }

    const content = items.map(({
      employee, id, currency, salary, otherCharge, fees, total,
    }) => (
      <tr key={id}>
        <td>{!!employee.email && employee.email}</td>
        <td>{!!employee.phoneNumber && formatPhoneNumber(employee.phoneCountryPrefix, employee.phoneNumber)}</td>
        <td>{employee.lastName}</td>
        <td>{employee.firstName}</td>
        <td>{money(salary, currency)}</td>
        <td>{money(otherCharge, currency)}</td>
        <td>{money(fees, currency)}</td>
        <td>{money(total, currency)}</td>
      </tr>
    ));
    return (
      <div className="table-container">
        <table className="table is-striped">
          <thead>
            <tr>
              <th>{t('Email')}</th>
              <th>{t('Téléphone')}</th>
              <th>{t('Nom')}</th>
              <th>{t('Prénom')}</th>
              <th>{t('Salaire')}</th>
              <th>{t('Autres charges')}</th>
              <th>{t('Fees')}</th>
              <th>{t('Total')}</th>
            </tr>
          </thead>
          <tbody>{content}</tbody>
        </table>
      </div>
    );
  }

  renderBulkGet() {
    const { data } = this.state;
    if (!data) {
      return null;
    }

    let timezoneTips = null;
    if (data.scheduledAt.timezone !== data.memberTimeZoneScheduledAt.timezone) {
      timezoneTips = (
        <div>
          <em>
            {t('Vous aviez choisit le fuseau horaire ')}
            {data.scheduledAt.timezone}
            {t(' pour le ')}
            {formatDate(data.scheduledAt, 'DD MMM HH:mm')}
          </em>
        </div>
      );
    }

    return (
      <div>
        <div className="columns is-multiline section">
          <div className="column is-half">
            <strong>
              {t('Programmé pour le')}
              {' : '}
            </strong>
            <span>{formatDate(data.memberTimeZoneScheduledAt, 'DD MMM YYYY HH:mm')}</span>
            {timezoneTips}
          </div>

          <div className="column is-half">
            <strong>
              {t('Communication')}
              {' : '}
            </strong>
            <span>{data.communication}</span>
          </div>

          <div className="column is-half">
            <strong>
              {t('Nb. employés')}
              {' : '}
            </strong>
            <span>{data.number}</span>
          </div>

          <div className="column is-half">
            <strong>
              {t('Statut')}
              {' : '}
            </strong>
            <span>{data.status}</span>
          </div>

          <div className="column is-half">
            <strong>
              {t('Total')}
              {' : '}
            </strong>
            <span>{money(data.total, data.currency)}</span>
          </div>

          <div className="column is-half">
            <strong>
              {t('Salaires')}
              {' : '}
            </strong>
            <span>{money(data.salaryTotal, data.currency)}</span>
          </div>

          <div className="column is-half">
            <strong>
              {t('Autre frais')}
              {' : '}
            </strong>
            <span>{money(data.otherChargesTotal, data.currency)}</span>
          </div>

          <div className="column is-half">
            <strong>
              {t('Fees')}
              {' : '}
            </strong>
            <span>{money(data.feesTotal, data.currency)}</span>
          </div>
        </div>

        {this.renderItems(data.items)}
      </div>
    );
  }

  render() {
    const { data } = this.state;
    return (
      <div className={classNames({ 'is-loading': data === null })}>
        <div className="container">
          <div className="section">
            <h1 className="title">{t('Paiement de salaire')}</h1>
          </div>
          {this.renderBulkGet()}
        </div>
      </div>
    );
  }
}

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  bulkGet: BulkGet;
};
const mapDispatchToProps: MapDispatchToProps = (dispatch: Dispatch) => ({
  bulkGet: payload => new Promise((resolve, reject) => {
    dispatch(SalaryPaymentBulkActionCreators.get(payload, resolve, reject));
  }),
});

export default connect(
  null,
  mapDispatchToProps,
)(BulkViewScreen);
