import { ActionReject, ActionResolve } from '../../../redux/promises';
import {
  ApiSalaryPaymentEmployeeAddPayload,
  ApiSalaryPaymentEmployeeAddResponseData,
  ApiSalaryPaymentEmployeeEditPayload,
  ApiSalaryPaymentEmployeeListResponseData,
  ApiSalaryPaymentEmployeePatchPayload,
} from '../../../api/salary-payment-employee-api';

// Types

export const SALARY_PAYMENT_EMPLOYEE_ACTION_LIST = 'SALARY_PAYMENT_EMPLOYEE_ACTION_LIST';
export const SALARY_PAYMENT_EMPLOYEE_ACTION_ADD = 'SALARY_PAYMENT_EMPLOYEE_ACTION_ADD';
export const SALARY_PAYMENT_EMPLOYEE_ACTION_EDIT = 'SALARY_PAYMENT_EMPLOYEE_ACTION_EDIT';
export const SALARY_PAYMENT_EMPLOYEE_ACTION_PATCH = 'SALARY_PAYMENT_EMPLOYEE_ACTION_PATCH';

// Actions creators definitions

// -- employeeListLoad

export type SalaryPaymentEmployeeActionListPayloadStatus = 'all' | 'active' | 'inactive';

export interface SalaryPaymentEmployeeActionListPayload {
  status: SalaryPaymentEmployeeActionListPayloadStatus;
}

export interface SalaryPaymentEmployeeActionListResolvePayload {
  data: ApiSalaryPaymentEmployeeListResponseData;
}

export interface SalaryPaymentEmployeeActionList {
  type: typeof SALARY_PAYMENT_EMPLOYEE_ACTION_LIST;
  payload: SalaryPaymentEmployeeActionListPayload;
  resolve: ActionResolve<SalaryPaymentEmployeeActionListResolvePayload>;
  reject: ActionReject;
}

// -- add

export interface SalaryPaymentEmployeeActionAddPayload extends ApiSalaryPaymentEmployeeAddPayload {}

export interface SalaryPaymentEmployeeActionAddResolvePayload {
  data: ApiSalaryPaymentEmployeeAddResponseData;
}

export interface SalaryPaymentEmployeeActionAdd {
  type: typeof SALARY_PAYMENT_EMPLOYEE_ACTION_ADD;
  payload: SalaryPaymentEmployeeActionAddPayload;
  resolve: ActionResolve<SalaryPaymentEmployeeActionAddResolvePayload>;
  reject: ActionReject;
}

// -- edit

export interface SalaryPaymentEmployeeActionEditPayload extends ApiSalaryPaymentEmployeeEditPayload {}

export interface SalaryPaymentEmployeeActionEditResolvePayload {
  data: ApiSalaryPaymentEmployeeAddResponseData;
}

export interface SalaryPaymentEmployeeActionEdit {
  type: typeof SALARY_PAYMENT_EMPLOYEE_ACTION_EDIT;
  payload: SalaryPaymentEmployeeActionEditPayload;
  resolve: ActionResolve<SalaryPaymentEmployeeActionEditResolvePayload>;
  reject: ActionReject;
}

// -- patch

export interface SalaryPaymentEmployeeActionPatchPayload extends ApiSalaryPaymentEmployeePatchPayload {}

export interface SalaryPaymentEmployeeActionPatchResolvePayload {
  data: ApiSalaryPaymentEmployeeAddResponseData;
}

export interface SalaryPaymentEmployeeActionPatch {
  type: typeof SALARY_PAYMENT_EMPLOYEE_ACTION_PATCH;
  payload: SalaryPaymentEmployeeActionPatchPayload;
  resolve: ActionResolve<SalaryPaymentEmployeeActionPatchResolvePayload>;
  reject: ActionReject;
}

// -- enum

export type SalaryPaymentEmployeeActions =
  | SalaryPaymentEmployeeActionList
  | SalaryPaymentEmployeeActionAdd
  | SalaryPaymentEmployeeActionEdit
  | SalaryPaymentEmployeeActionPatch;

// Action creators

export const SalaryPaymentEmployeeActionCreators = {
  list: (
    payload: SalaryPaymentEmployeeActionListPayload,
    resolve: ActionResolve<SalaryPaymentEmployeeActionListResolvePayload>,
    reject: ActionReject,
  ): SalaryPaymentEmployeeActionList => ({
    type: SALARY_PAYMENT_EMPLOYEE_ACTION_LIST,
    payload,
    resolve,
    reject,
  }),
  add: (
    payload: SalaryPaymentEmployeeActionAddPayload,
    resolve: ActionResolve<SalaryPaymentEmployeeActionAddResolvePayload>,
    reject: ActionReject,
  ): SalaryPaymentEmployeeActionAdd => ({
    type: SALARY_PAYMENT_EMPLOYEE_ACTION_ADD,
    payload,
    resolve,
    reject,
  }),
  edit: (
    payload: SalaryPaymentEmployeeActionEditPayload,
    resolve: ActionResolve<SalaryPaymentEmployeeActionEditResolvePayload>,
    reject: ActionReject,
  ): SalaryPaymentEmployeeActionEdit => ({
    type: SALARY_PAYMENT_EMPLOYEE_ACTION_EDIT,
    payload,
    resolve,
    reject,
  }),
  patch: (
    payload: SalaryPaymentEmployeeActionPatchPayload,
    resolve: ActionResolve<SalaryPaymentEmployeeActionPatchResolvePayload>,
    reject: ActionReject,
  ): SalaryPaymentEmployeeActionPatch => ({
    type: SALARY_PAYMENT_EMPLOYEE_ACTION_PATCH,
    payload,
    resolve,
    reject,
  }),
};
