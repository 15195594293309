// inspired by https://gist.github.com/leecrossley/6027780

const generateImageFromLetterDirect = (letters: string, size: number) => {
  const colours = [
    '#1abc9c',
    '#2ecc71',
    '#3498db',
    '#9b59b6',
    '#34495e',
    '#16a085',
    '#27ae60',
    '#2980b9',
    '#8e44ad',
    '#2c3e50',
    '#f1c40f',
    '#e67e22',
    '#e74c3c',
    '#f10e6c',
    '#95a5a6',
    '#f39c12',
    '#d35400',
    '#c0392b',
    '#bdc3c7',
    '#7f8c8d',
  ];

  const charIndex = (letters === '?' ? 72 : letters.charCodeAt(0)) - 64;
  const colourIndex = charIndex % colours.length;
  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext('2d');
  if (context) {
    context.fillStyle = colours[colourIndex - 1];
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = `${Math.round(canvas.width / 2)}px Arial`;
    context.textAlign = 'center';
    context.fillStyle = '#FFF';
    context.fillText(letters, size / 2, size / 1.5);

    const dataURI = canvas.toDataURL();
    return dataURI;
  }

  return '';
};

const imageCache: { [key: string]: string } = {};

export const generateImageFromLetter = (letters: string, size: number) => {
  const key = `${letters}-${size}`;
  if (!imageCache[key]) {
    imageCache[key] = generateImageFromLetterDirect(letters, size);
  }
  return imageCache[key];
};

const nameCache: { [key: string]: string } = {};
export const generateImageFromName = (name: string, size: number) => {
  if (!nameCache[name]) {
    const letters = name
      .split(/\s+/)
      .map(part => part.charAt(0))
      .join('')
      .substring(0, 2)
      .toUpperCase();
    nameCache[name] = letters;
  }

  return generateImageFromLetter(nameCache[name], size);
};
