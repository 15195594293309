import { ApiRequest } from 'koosmik-common';
import { ApiDate, ApiDefaultResponse } from './shared-api-lib';

interface ApiMoneyOperationListPayload {
  // eslint-disable-next-line camelcase
  since_id?: string;
  // eslint-disable-next-line camelcase
  max_id?: string;
  count?: number;
}

export interface ApiMoneyOperation {
  id: string;
  type: string;
  label: string | null;
  amount: number;
  fees: number;
  currency: string;
  isNegative: boolean;
  isPending: boolean;
  executedAt: ApiDate;
  otherMember: {
    displayName: string;
    shortName: string;
    avatar: {
      small: string;
      medium: string;
      large: string;
    };
    initial: string;
  } | null;
  bankAccount?: {
    name: string;
  };
  card?: {
    label: string;
  };
}

export interface ApiMoneyOperationListResponseData {
  count: number;
  following: {
    // eslint-disable-next-line camelcase
    max_id: string | null;
  };
  previous: {
    // eslint-disable-next-line camelcase
    since_id: string | null;
  };
  results: Array<ApiMoneyOperation>;
  totalCount: number;
}

export interface ApiMoneyOperationListResponse extends ApiDefaultResponse<ApiMoneyOperationListResponseData> {}

type ApiMoneyOperationList = (payload: ApiMoneyOperationListPayload) => Promise<ApiMoneyOperationListResponse>;
export const apiMoneyOperationList = ApiRequest.api.moneyOperations.moneyOperationsList as ApiMoneyOperationList;
