import React from 'react';
import { RouteComponentProps } from 'react-router';
import { RoutesUrls } from '../../../../config/Routes';

class SignIn404Screen extends React.PureComponent<RouteComponentProps> {
  buttonClick = () => {
    const { history } = this.props;
    history.push(RoutesUrls.dashboard);
  };

  render() {
    return (
      <div>
        <h1>Page not found</h1>
        <p>
          <button type="button" onClick={this.buttonClick}>
            Back
          </button>
        </p>
      </div>
    );
  }
}

export default SignIn404Screen;
