import {
  call, select, put, takeEvery,
} from 'redux-saga/effects';
import {
  CASH_FEES_GRID_ACTION_LOAD,
  CashActionFeesGridLoad,
  CashFeesGridActionCreators,
} from './cash-fees-grid-actions';
import { apiProCashFeesGrid, ApiProCashFeesGridResponse } from '../../../../api/pro-cash-api';
import { apiCallWrapper } from '../../../../tools/functions/api-call-handler';
import { cashFeesGridSelect } from './cash-fees-grid-selectors';

function* cashFeesGridLoad({ resolve, reject }: CashActionFeesGridLoad) {
  try {
    const cashFeesGrid = yield select(cashFeesGridSelect);
    if (cashFeesGrid) {
      resolve({ data: cashFeesGrid });
      return;
    }

    const response: ApiProCashFeesGridResponse = yield call(apiCallWrapper, apiProCashFeesGrid);

    const { success, data, errorCode } = response;
    if (success) {
      yield put(CashFeesGridActionCreators.save(data));
      resolve({ data });
      return;
    }

    reject({ errorCode });
  } catch (error) {
    reject({ error });
  }
}

export default function* cashFeesGridSaga() {
  yield takeEvery(CASH_FEES_GRID_ACTION_LOAD, cashFeesGridLoad);
}
