import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
import rootSagas from './root-sagas';
import rootReducers from './root-reducers';
import history from '../config/history';
import persistConfig from '../config/persist-config';

export default function initStore() {
  let sagaMiddleware = null;
  let enhancer = null;

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line global-require
    const reactotronConfig = require('../config/reactotron-config').default;
    // eslint-disable-next-line global-require
    const loggerMiddleware = require('../config/logger-config').default;

    const sagaMonitor = reactotronConfig.createSagaMonitor();

    sagaMiddleware = createSagaMiddleware({ sagaMonitor });
    enhancer = compose(
      applyMiddleware(routerMiddleware(history), sagaMiddleware, loggerMiddleware),
      reactotronConfig.createEnhancer(),
    );
  } else {
    sagaMiddleware = createSagaMiddleware();
    enhancer = compose(applyMiddleware(routerMiddleware(history), sagaMiddleware));
  }

  const persistedReducer = persistReducer(persistConfig, rootReducers);

  const store = createStore(persistedReducer, {}, enhancer);
  const persistor = persistStore(store);

  sagaMiddleware.run(rootSagas);

  return { store, persistor };
}
