export const KEYS = {
  BACKSPACE: 'Backspace',
  TAB: 'Tab',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  SPACE: 'Space',
  PAGE_UP: 'PageUp',
  PAGE_DOWN: 'PageDown',
  END: 'End',
  HOME: 'Home',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_UP: 'ArrowUp',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_DOWN: 'ArrowDown',
};

const keyCodesMapping: { [key: number]: string } = {
  8: KEYS.BACKSPACE,
  9: KEYS.TAB,
  13: KEYS.ENTER,
  27: KEYS.ESCAPE,
  32: KEYS.SPACE,
  33: KEYS.PAGE_UP,
  34: KEYS.PAGE_DOWN,
  35: KEYS.END,
  36: KEYS.HOME,
  37: KEYS.ARROW_LEFT,
  38: KEYS.ARROW_UP,
  39: KEYS.ARROW_RIGHT,
  40: KEYS.ARROW_DOWN,
};

export const getKeyCode = (event: KeyboardEvent): string | undefined => {
  const { code } = event;
  if (code) {
    return code;
  }

  const keyCode: number = event.keyCode || event.which;
  return keyCodesMapping[keyCode];
};

export const isKeyPrintable = (event: KeyboardEvent) => {
  const keyCode: number = event.keyCode || event.which;

  return (
    (keyCode > 47 && keyCode < 58) // number keys
    || keyCode === 32 // spacebar
    || (keyCode > 64 && keyCode < 91) // letter keys
    || (keyCode > 95 && keyCode < 112) // numpad keys
    || (keyCode > 185 && keyCode < 193) // ;=,-./` (in order)
    || (keyCode > 218 && keyCode < 223)
  ); // [\]' (in order)
};

export const getKeyChar = (event: KeyboardEvent) => {
  const keyCode = event.keyCode || event.which;
  return String.fromCharCode(keyCode);
};
