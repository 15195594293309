import { Observable, OperatorFunction } from 'rxjs';

export function bufferLast<T>(closingNotifier: Observable<any>): OperatorFunction<T, T[]> {
  return function bufferLastOperatorFunction(source: Observable<T>) {
    return new Observable<T[]>((subscriber) => {
      let buffer: T[] = [];
      source.subscribe((v) => {
        buffer = [...buffer, v];
        subscriber.next(buffer);
      });

      closingNotifier.subscribe(() => {
        buffer = [];
      });
    });
  };
}
