import { takeEvery } from 'redux-saga/effects';
import { ApiRequest } from 'koosmik-common';
import { AUTH_ACTION_CLEAR, AUTH_ACTION_SET, AuthActionSet } from './auth-actions';

function authSet({ payload }: AuthActionSet) {
  ApiRequest.setAuthorizationBearer(payload.jwtToken);
}

function authClear() {
  ApiRequest.setAuthorizationBearer('');
}

export default function* authSaga() {
  yield takeEvery(AUTH_ACTION_SET, authSet);
  yield takeEvery(AUTH_ACTION_CLEAR, authClear);
}
