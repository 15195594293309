import { ApiRequest } from 'koosmik-common';
import { ApiDate, ApiDefaultResponse, ApiResponseDataInputErrors } from './shared-api-lib';
import { Employee } from './salary-payment-employee-api';

const api = ApiRequest.api.salaryPayment;

export type SalaryPaymentBulkItemStatus = 'scheduled' | 'failed' | 'sent' | 'cancelled';

export interface SalaryPaymentBulkItem {
  id: string;
  status: SalaryPaymentBulkItemStatus;
  currency: string;
  cancellable: boolean;
  salary: number;
  otherCharge: number;
  fees: number;
  total: number;
  hourlyRate: number | null;
  employee: Employee;
}

export type SalaryPaymentBulkStatus = 'scheduled' | 'running' | 'cancelled' | 'finished';
export interface SalaryPaymentBulk {
  id: string;
  communication: string;
  number: number;
  salaryTotal: number;
  otherChargesTotal: number;
  feesTotal: number;
  total: number;
  currency: string;
  scheduledAt: ApiDate;
  memberTimeZoneScheduledAt: ApiDate;
  status: SalaryPaymentBulkStatus;
}

export interface SalaryPaymentBulkDetails extends SalaryPaymentBulk {
  items: Array<SalaryPaymentBulkItem>;
}

// Salary payment bulk employeeListLoad

export interface ApiSalaryPaymentBulkListPayload {
  // eslint-disable-next-line camelcase
  since_id?: string;
  // eslint-disable-next-line camelcase
  max_id?: string;
  count?: number;
}

export interface ApiSalaryPaymentBulkListResponseData {
  count: number;
  following: {
    // eslint-disable-next-line camelcase
    max_id: string;
  } | null;
  previous: {
    // eslint-disable-next-line camelcase
    since_id: string;
  } | null;
  results: Array<SalaryPaymentBulk>;
}

export interface ApiSalaryPaymentBulkListResponse extends ApiDefaultResponse<ApiSalaryPaymentBulkListResponseData> {}

type ApiSalaryPaymentBulkList = (payload: ApiSalaryPaymentBulkListPayload) => Promise<ApiSalaryPaymentBulkListResponse>;
export const apiSalaryPaymentBulkList = api.getBulkList as ApiSalaryPaymentBulkList;

// Salary payment bulk get

export interface ApiSalaryPaymentBulkGetPayload {
  bulkId: string;
}

export type ApiSalaryPaymentBulkGetResponseData = SalaryPaymentBulkDetails;

export interface ApiSalaryPaymentBulkGetResponse extends ApiDefaultResponse<ApiSalaryPaymentBulkGetResponseData> {}

type ApiSalaryPaymentBulkGet = (payload: ApiSalaryPaymentBulkGetPayload) => Promise<ApiSalaryPaymentBulkGetResponse>;
export const apiSalaryPaymentBulkGet = api.getBulk as ApiSalaryPaymentBulkGet;

// Salary payment bulk create

export interface ApiSalaryPaymentBulkCreatePayload {
  communication: string;
  scheduledAt?: string;
  timeZone: string;
  currency: string;
  employees: Array<{
    id: string;
    salary: number;
    otherCharge: number;
    fees: number;
    hourlyRate: number | null;
  }>;
}

export interface ApiSalaryPaymentBulkCreateResponseData extends SalaryPaymentBulk {
  inputErrors?: ApiResponseDataInputErrors;
}

export interface ApiSalaryPaymentBulkCreateResponse
  extends ApiDefaultResponse<ApiSalaryPaymentBulkCreateResponseData> {}

type ApiSalaryPaymentBulkCreate = (
  payload: ApiSalaryPaymentBulkCreatePayload,
) => Promise<ApiSalaryPaymentBulkCreateResponse>;
export const apiSalaryPaymentBulkCreate = api.createBulk as ApiSalaryPaymentBulkCreate;
