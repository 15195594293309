import { AuthStateNotNull } from './auth-state';

// Types

export const AUTH_ACTION_SET = 'AUTH_ACTION_SET';
export const AUTH_ACTION_CLEAR = 'AUTH_ACTION_CLEAR';

// Action creators definitions

export interface AuthActionSet {
  type: typeof AUTH_ACTION_SET;
  payload: AuthStateNotNull;
}

export interface AuthActionClear {
  type: typeof AUTH_ACTION_CLEAR;
}

export type AuthActions = AuthActionSet | AuthActionClear;

// Action creators

export const AuthActionCreators = {
  set: (payload: AuthStateNotNull): AuthActionSet => ({
    type: AUTH_ACTION_SET,
    payload,
  }),
  clear: (): AuthActionClear => ({
    type: AUTH_ACTION_CLEAR,
  }),
};
