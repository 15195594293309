import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import classNames from 'classnames';
import RootState from '../../../redux/RootState';
import UserState from '../../../redux/user/user-state';
import {
  MoneyOperationActionCreators,
  MoneyOperationActionListResolvePayload,
  MoneyOperationActionListPayload,
} from '../redux/money-operation-actions';
import { ApiMoneyOperation, ApiMoneyOperationListResponseData } from '../../../api/money-operation-api';
import { actionRejectHandler } from '../../../tools/functions/action-promise-handler';
import MoneyOperationRow from '../components/MoneyOperationRow';
import { ApiDate } from '../../../api/shared-api-lib';
import { datesIsSameDay, formatDateLocale } from '../../../tools/functions/date';
import { money } from '../../../tools/functions/money';
import { t } from '../../../tools/functions/translation';

type MoneyOperationList = (payload: MoneyOperationActionListPayload) => Promise<MoneyOperationActionListResolvePayload>;

interface Props {
  user: UserState;
  moneyOperationList: MoneyOperationList;
}

interface Group {
  date: ApiDate;
  total: number;
  currency: string;
  list: Array<ApiMoneyOperation>;
}

interface State {
  groups: Array<Group>;
  following: string | null;
  isLoading: boolean;
  isLoadingMore: boolean;
}

class DashboardScreen extends React.PureComponent<Props, State> {
  state: State = {
    groups: [],
    following: null,
    isLoading: true,
    isLoadingMore: false,
  };

  componentDidMount() {
    const { moneyOperationList } = this.props;
    moneyOperationList({ maxId: '' })
      .then(({ data }) => {
        this.setState({
          groups: this.mergeInGroup(data),
          following: data.following.max_id,
          isLoading: false,
        });
      })
      .catch(actionRejectHandler());
  }

  mergeInGroup = (data: ApiMoneyOperationListResponseData) => {
    const { groups } = this.state;

    const head = [...groups];
    let tail = head.pop() || null;
    if (tail) {
      tail = { ...tail, list: [...tail.list] };
    }

    data.results.forEach((v) => {
      if (tail) {
        if (!datesIsSameDay(tail.date, v.executedAt)) {
          head.push(tail);
          tail = null;
        }
      }

      if (!tail) {
        tail = {
          date: v.executedAt,
          currency: v.currency,
          total: 0,
          list: [],
        };
      }

      if (v.isNegative) {
        tail.total -= v.amount;
      } else {
        tail.total += v.amount;
      }
      tail.list.push(v);
    });

    if (tail) {
      head.push(tail);
    }

    return head;
  };

  onRequestedLoadMore = () => {
    const { moneyOperationList } = this.props;
    const { following, isLoadingMore } = this.state;

    if (!following || isLoadingMore) {
      return;
    }

    this.setState({ following: null, isLoadingMore: true });
    moneyOperationList({ maxId: following })
      .then(({ data }) => {
        this.setState({
          groups: this.mergeInGroup(data),
          following: data.following.max_id,
        });
      })
      .catch(actionRejectHandler())
      .finally(() => {
        this.setState({ isLoadingMore: false });
      });
  };

  renderLoadMoreButton() {
    const { following, isLoadingMore } = this.state;

    if (!following && !isLoadingMore) {
      return null;
    }

    return (
      <div className="has-text-centered">
        <button
          type="submit"
          className={classNames('button is-primary is-large is-outlined', { 'is-loading': isLoadingMore })}
          disabled={isLoadingMore}
          onClick={this.onRequestedLoadMore}
        >
          {t('Voir plus de résultats')}
        </button>
      </div>
    );
  }

  renderGroups() {
    const { groups } = this.state;
    return groups.map(({
      date, total, currency, list,
    }) => (
      <div className="card money-operation-group" key={date.timestamp}>
        <header className="card-header">
          <p className="card-header-title">{formatDateLocale(date, 'long', 'none')}</p>
          <span className="money">{money(total, currency)}</span>
        </header>
        <div className="card-content list">
          {list.map(item => (
            <MoneyOperationRow moneyOperation={item} key={item.id} />
          ))}
        </div>
      </div>
    ));
  }

  render() {
    const { isLoading } = this.state;

    return (
      <div id="dashboard" className={classNames('hero-body is-fullwidth', { 'is-loading': isLoading })}>
        {this.renderGroups()}

        {this.renderLoadMoreButton()}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  moneyOperationList: MoneyOperationList;
};
const mapDispatchToProps: MapDispatchToProps = dispatch => ({
  moneyOperationList: (payload: MoneyOperationActionListPayload) => new Promise((resolve, reject) => {
    dispatch(MoneyOperationActionCreators.moneyOperationList(payload, resolve, reject));
  }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardScreen);
