import { ActionReject, ActionResolve } from '../../../../redux/promises';
import { CashFeesGridStateNotNull } from './cash-fees-grid-state';

// Types

export const CASH_FEES_GRID_ACTION_LOAD = 'CASH_FEES_GRID_ACTION_LOAD';
export const CASH_FEES_GRID_ACTION_SAVE = 'CASH_FEES_GRID_ACTION_SAVE';

// Actions creator definitions

// -- load

export interface CashActionFeesGridLoadResolvePayload {
  data: CashFeesGridStateNotNull;
}

export interface CashActionFeesGridLoad {
  type: typeof CASH_FEES_GRID_ACTION_LOAD;
  resolve: ActionResolve<CashActionFeesGridLoadResolvePayload>;
  reject: ActionReject;
}

// -- save

export interface CashActionFeesGridSave {
  type: typeof CASH_FEES_GRID_ACTION_SAVE;
  payload: CashFeesGridStateNotNull;
}

// -- enum

export type CashFeesGridActions = CashActionFeesGridLoad | CashActionFeesGridSave;

// Action creators

export const CashFeesGridActionCreators = {
  load: (
    resolve: ActionResolve<CashActionFeesGridLoadResolvePayload>,
    reject: ActionReject,
  ): CashActionFeesGridLoad => ({
    type: CASH_FEES_GRID_ACTION_LOAD,
    resolve,
    reject,
  }),
  save: (payload: CashFeesGridStateNotNull): CashActionFeesGridSave => ({
    type: CASH_FEES_GRID_ACTION_SAVE,
    payload,
  }),
};
