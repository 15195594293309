import RootState from '../RootState';

// prettier-ignore
export const sessionsSelectOneByEmail = (state: RootState, email: string) => (
  state.sessions.find(
    el => el.type === 'email'
      && el.email === email,
  )
);

// prettier-ignore
export const sessionsSelectOneByPhone = (state: RootState, countryCode: string, phoneNumber: string) => (
  state.sessions.find(
    el => el.type === 'phone'
      && el.phone.country === countryCode
      && el.phone.number === phoneNumber,
  )
);

export const sessionsSelectLast = (state: RootState) => state.sessions[0] || null;
