import moment from 'moment-timezone';
import 'moment/locale/fr';
import reactWidgetsMoment from 'react-widgets-moment';
import { TranslationCountry, TranslationFormInputError, TranslationServerErrorCode } from 'koosmik-common/translations';

let currentLocale = '';

export const getLocale = () => currentLocale;

export default function initLocale(locale: string) {
  currentLocale = locale;
  moment.locale(locale);
  reactWidgetsMoment();
  TranslationServerErrorCode.setLocale(locale);
  TranslationCountry.setLocale(locale);
  TranslationFormInputError.setLocale(locale);
}
