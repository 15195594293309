import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import RootState from './RootState';
import history from '../config/history';
import sessionsReducer from './sessions/sessions-reducer';
import authReducer from './auth/sessions-reducer';
import userReducer from './user/user-reducer';
import cashFeesGridReducer from '../modules/shared/redux/cash-fees-grid/cash-fees-grid-reducer';
import deviceReducer from './device/device-reducer';

export default combineReducers<RootState>({
  router: connectRouter(history),
  device: deviceReducer,
  sessions: sessionsReducer,
  auth: authReducer,
  user: userReducer,
  cache: combineReducers({
    cashFeesGrid: cashFeesGridReducer,
  }),
});
