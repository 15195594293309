import { DeviceActions, DEVICE_ACTION_SET } from './device-actions';
import DeviceState from './device-state';

const initialState: DeviceState = {
  id: null,
};

export default function deviceReducer(state: DeviceState = initialState, action: DeviceActions) {
  switch (action.type) {
    case DEVICE_ACTION_SET:
      return action.payload;
    default:
      return state;
  }
}
