import { ApiRequest } from 'koosmik-common';
import { ApiDefaultResponse } from './shared-api-lib';

export interface ApiMeResponseData {
  area: string;
  avatar: {
    small: string;
    medium: string;
    large: string;
  } | null;
  email: string | null;
  initial: string;
  kycLevel: string;
  kycType: string;
  phoneNumber: {
    id: string;
    country: string;
    countryPrefix: string;
    obstructedNumber: string;
    isPrimary: boolean;
    isVerified: boolean;
  } | null;
  preferredCurrency: string;
  displayName: string;
  wallets: {
    [currency: string]: {
      balance: number;
      blocked: number;
    };
  };
  enabledFeatures: string[];
  restrictedFeatures: string[];
}

export interface ApiMeResponse extends ApiDefaultResponse<ApiMeResponseData> {}

type ApiMe = () => Promise<ApiMeResponse>;
export const apiMe = ApiRequest.api.me.me as ApiMe;
