import { ApiRequest } from 'koosmik-common';
import { ApiDefaultResponse } from './shared-api-lib';

const api = ApiRequest.api.proCash;

export interface ApiProCashFeesGridResponseData {
  withdraw: {
    fromAmount: {
      [currency: string]: Array<{
        min: number;
        max: number;
        fees: number;
      }>;
    };
    fromTotal: {
      [currency: string]: Array<{
        min: number;
        max: number;
        fees: number;
      }>;
    };
  };
}

export interface ApiProCashFeesGridResponse extends ApiDefaultResponse<ApiProCashFeesGridResponseData> {}

type ApiProCashFeesGrid = () => Promise<ApiProCashFeesGridResponse>;
export const apiProCashFeesGrid = api.getCashFeesGrid as ApiProCashFeesGrid;
